import React, { useState, useContext, useEffect } from 'react';
import {
  Button, Row, Col, Modal, Form, InputGroup,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import MiniGoal from './MiniGoal';
import GoalService from '../services/GoalService';
import SunService from '../services/SunService';
import { AdminContext } from '../contexts/AdminContext';

function AdminGlobalGoalModal({
  open, onHide, number, conflictsAndSynergies,
}) {
  const { t } = useTranslation();

  const [adminData, setAdminData] = useContext(AdminContext);

  const [goal] = useState(
    adminData.goals.filter(
      (c) => c.mainGoalNumber === number && c.subGoalNumber === '0',
    )[0],
  );
  const [remainingGoalsThatCanBeAdded, setRemainingGoalsThatCanBeAdded] = useState(
    adminData.goals.filter(
      (c) => c.mainGoalNumber === number,
    ),
  );

  const [selectedGoalToAdd, setSelectedGoalToAdd] = useState({});
  const [goalsToRender, setgoalsToRender] = useState([]);
  const [goalsToSendToAPI, setGoalsToSendToAPI] = useState([]);

  const handleSelectedOptionToAdd = (e) => {
    const [mainGoalNumber, subGoalNumber] = e.target.value.split(' ')[0].split('.');
    setSelectedGoalToAdd(
      ...adminData.goals.filter(
        (c) => c.mainGoalNumber === Number(mainGoalNumber) && c.subGoalNumber === subGoalNumber,
      ),
    );
  };

  const handleDeleteGoal = (goalToDelete) => {
    const goalsToRenderAfterDelete = goalsToRender.filter(
      (c) => c.subGoalNumber !== goalToDelete.subGoalNumber,
    );
    const goalsThatCanBeAdded = remainingGoalsThatCanBeAdded.concat(goalToDelete);
    setgoalsToRender(goalsToRenderAfterDelete);
    setRemainingGoalsThatCanBeAdded(goalsThatCanBeAdded);
  };

  const handleAddedGoal = (e) => {
    e.preventDefault();
    setgoalsToRender([...goalsToRender, selectedGoalToAdd]);
    setRemainingGoalsThatCanBeAdded(remainingGoalsThatCanBeAdded.filter((c) => c.subGoalNumber !== selectedGoalToAdd.subGoalNumber));
  };

  const handleSaveAdminGoals = async () => {
    await GoalService.registerSynergiesAndGoalsToAdminLevel(goalsToSendToAPI);
    await SunService.syncAdminData(adminData.adminConflictsAndSynergies);
    onHide();
  };

  const addGoalWitCS = (goalToAdd) => {
    if (goalsToSendToAPI.find((c) => c.id === goalToAdd.id)) {
      setGoalsToSendToAPI([...goalsToSendToAPI.filter((c) => c.id !== goalToAdd.id), goalToAdd]);
    } else {
      setGoalsToSendToAPI([...goalsToSendToAPI, goalToAdd]);
    }
  };

  return (
    <>
      <Modal className="modal-page" show={open} onHide={onHide}>
        <Modal.Header className={`goal-bgcolor-${number}`}>
          <Modal.Title>
            <i className="fal fa-cog mr-2" />
            <span>
              {goal.mainGoalNumber}
              {' '}
              {goal.name}
            </span>
          </Modal.Title>
          <Button variant="text" className="close" onClick={onHide} aria-label={t('close')}><span>{t('close')}</span></Button>
        </Modal.Header>
        <Modal.Body>

          <div className="add-target">
            <fieldset className="pt-2 pb-3 pt-lg-3 pb-lg-5 px-md-3 px-lg-5">
              <Row>
                <Col>
                  <label className="mb-2 mb-lg-3">{t('adminGoalLead')}</label>
                  <InputGroup>
                    <Form.Control as="select" id="addTargetSelect" defaultValue={t('goalTargetSelect')} onChange={handleSelectedOptionToAdd}>
                      <option>{t('goalTargetSelect')}</option>
                      {remainingGoalsThatCanBeAdded.map((g) => (
                        <option key={g.id}>
                          {g.mainGoalNumber}
                          .
                          {g.subGoalNumber}
                          {' '}
                          {g.name}
                        </option>
                      ))}
                    </Form.Control>
                    <InputGroup.Append>
                      <Button variant="primary" onClick={handleAddedGoal}>{t('add')}</Button>
                    </InputGroup.Append>
                  </InputGroup>
                </Col>
              </Row>
            </fieldset>
          </div>

          {goalsToRender.sort(
            (a, b) => a.subGoalNumber - b.subGoalNumber,
          ).map((g) => (
            <MiniGoal
              key={g.id}
              allGoals={adminData.goals}
              conflictsAndSynergies={conflictsAndSynergies}
              goalToRender={g}
              handleDeleteGoal={handleDeleteGoal}
              addGoalWitCS={addGoalWitCS}
            />
          ))}

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className="btn-lg" onClick={onHide}>{t('cancel')}</Button>
          <Button variant="primary" className="btn-lg" onClick={handleSaveAdminGoals}>{t('save')}</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AdminGlobalGoalModal;
