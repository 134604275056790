import React, { } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function DeleteModal({ open, onHide }) {
  const { t } = useTranslation();

  return (
    <>
      <Modal show={open} onHide={onHide}>
        <Modal.Header>
          <Modal.Title>{t('delete')}</Modal.Title>
          <Button variant="text" className="close" onClick={onHide} aria-label={t('close')}><span>{t('close')}</span></Button>
        </Modal.Header>
        <Modal.Body>
          <div className="dialog">
            <div className="dialog-body">
              <h5>
                <i className="far fa-exclamation-circle mr-2" />
                {t('deleteConfirm')}
              </h5>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>{t('cancel')}</Button>
          <Button variant="danger" onClick={onHide}>{t('delete')}</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DeleteModal;
