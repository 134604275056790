import React, {
  useEffect, useState, useContext, useRef,
} from 'react';
import {
  Button, Row, Col, InputGroup,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Typeahead } from 'react-bootstrap-typeahead';
import MiniConflictSynergyAdmin from './MiniConflictSynergyAdmin';
import GoalService from '../services/GoalService';
import { AdminContext } from '../contexts/AdminContext';
import GlobalGoalMappingUrls from '../constants/GlobalGoalMappingUrls';

const MiniGoal = ({
  allGoals, goalToRender, addGoalWitCS,
}) => {
  const { t } = useTranslation();
  const conflictsTypeahead = useRef(null);
  const synergiesTypeahead = useRef(null);
  const [adminData, setAdminData] = useContext(AdminContext);
  const [selectedConflict, setSelectedConflict] = useState();

  const handleAddConflictClick = async () => {
    const conflictOrSynergy = {
      From: goalToRender,
      To: selectedConflict,
      Type: 1,
      Motivation: '',
    };
    const res = await GoalService.registerAdminNewConflictOrSynergy(conflictOrSynergy);
    setAdminData(res);
    conflictsTypeahead.current.clear();
  };

  const handleAddSynergyClick = async () => {
    const conflictOrSynergy = {
      From: goalToRender,
      To: selectedConflict,
      Type: 2,
      Motivation: '',
    };
    const res = await GoalService.registerAdminNewConflictOrSynergy(conflictOrSynergy);
    setAdminData(res);
    synergiesTypeahead.current.clear();
  };

  const handleDeleteSynergy = async (goalToRemove) => {
    const res = await GoalService.deleteAdminLevelConflictOrSynergy(goalToRender, goalToRemove, 2);
    setAdminData(res);
  };

  const handleDeleteConflict = async (goalToRemove) => {
    const res = await GoalService.deleteAdminLevelConflictOrSynergy(goalToRender, goalToRemove, 1);
    setAdminData(res);
  };

  return (
    <Row className="global-goal-target no-gutters">
      <Col className={`col-auto goal-bgcolor-${goalToRender.mainGoalNumber}`}>
        <Button variant="text" as="a" href={GlobalGoalMappingUrls[goalToRender.mainGoalNumber]} target="_blank" rel="noopener noreferrer" className={`btn-global-goal goal-bgcolor-${goalToRender.mainGoalNumber}`}>
          <figure>
            <figcaption>
              {goalToRender.mainGoalNumber}
              .
              {goalToRender.subGoalNumber}
            </figcaption>
            <img src={`/img/global-goal/targets/${goalToRender.mainGoalNumber}/${goalToRender.mainGoalNumber}.${goalToRender.subGoalNumber}.svg`} alt="" />
          </figure>
        </Button>
      </Col>
      <Col className="px-3 px-lg-5 pt-2 pt-lg-3">

        <label className="mb-1 mb-md-3 mr-3">{goalToRender.name}</label>
        <Row>
          <Col xs={12} sm={12} md={6} className="pr-md-2">
            <div className="goal-conflicts pt-2 pb-3 px-3 mb-3 mb-lg-5">
              <h5 className="mb-2">
                <i className="fas fa-heart-broken fa-lg mr-2" />
                {t('conflicts')}
              </h5>
              {adminData.adminConflictsAndSynergies.filter((g) => g.type === 1 && g.from.mainGoalNumber === goalToRender.mainGoalNumber && g.from.subGoalNumber === goalToRender.subGoalNumber).map((conflict) => (
                <MiniConflictSynergyAdmin
                  key={conflict.id}
                  goalToRender={conflict.to}
                  handleDelete={handleDeleteConflict}
                  parentGoal={goalToRender}
                  motivationFromUpperComponent={conflict.motivation}
                />
              ))}
              <InputGroup className="flex-nowrap">
                <Typeahead
                  className="w-100"
                  id="searchConflict1"
                  labelKey={(option) => `${option.mainGoalNumber}.${option.subGoalNumber} ${option.name}`}
                  options={allGoals}
                  placeholder={t('goalTargetSearch')}
                  clearButton
                  onChange={(selected) => setSelectedConflict(selected[0])}
                  ref={conflictsTypeahead}
                />
                <InputGroup.Append>
                  <Button variant="secondary">
                    <i className="far fa-plus fa-lg m-0" onClick={handleAddConflictClick} />
                    <span className="sr-only">{t('add')}</span>
                  </Button>
                </InputGroup.Append>
              </InputGroup>

            </div>
          </Col>
          <Col xs={12} sm={12} md={6} className="pl-md-2">
            <div className="goal-synergies pt-2 pb-3 px-3 mb-3">
              <h5 className="mb-2">
                <i className="far fa-heart fa-lg mr-2" />
                {t('synergies')}
              </h5>
              {adminData.adminConflictsAndSynergies.filter((g) => g.type === 2 && g.from.mainGoalNumber === goalToRender.mainGoalNumber && g.from.subGoalNumber === goalToRender.subGoalNumber).map((synergy) => (
                <MiniConflictSynergyAdmin
                  key={synergy.id}
                  goalToRender={synergy.to}
                  handleDelete={handleDeleteSynergy}
                  parentGoal={goalToRender}
                  motivationFromUpperComponent={synergy.motivation}
                />
              ))}
              <InputGroup className="flex-nowrap">
                <Typeahead
                  className="w-100"
                  id="searchSynergy1"
                  labelKey={(option) => `${option.mainGoalNumber}.${option.subGoalNumber} ${option.name}`}
                  options={allGoals}
                  placeholder={t('goalTargetSearch')}
                  clearButton
                  onChange={(selected) => setSelectedConflict(selected[0])}
                  ref={synergiesTypeahead}
                />
                <InputGroup.Append>
                  <Button variant="secondary" onClick={handleAddSynergyClick}>
                    <i className="far fa-plus fa-lg m-0" />
                    <span className="sr-only">{t('add')}</span>
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </div>
          </Col>
        </Row>
      </Col>
      <button type="button" className="close" aria-label={t('delete')}><span>{t('delete')}</span></button>
    </Row>
  );
};

export default MiniGoal;
