import React, {
  useState, useContext, useRef, useEffect,
} from 'react';
import {
  Button, Row, Col, InputGroup, Form, Collapse, ButtonGroup,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import TextareaAutosize from 'react-autosize-textarea';
import { Typeahead } from 'react-bootstrap-typeahead';
import MiniConflictSynergy from './MiniConflictSynergy';
import GoalService from '../services/GoalService';
import { SunContext } from '../contexts/SunContext';
import GlobalGoalMappingUrls from '../constants/GlobalGoalMappingUrls';

const ProjectMiniGoal = ({ goalToRender, allGoals, handleDeleteGoal }) => {
  const { t } = useTranslation();
  const typeaheadSynergy = useRef(null);
  const typeaheadConflict = useRef(null);
  const [sun, setSun] = useContext(SunContext);
  const [goalEffectCollapse1, setGoalEffectCollapse1] = useState(false);
  const [goalEffectCollapse12, setGoalEffectCollapse12] = useState(false);
  const [motivation, setMotivation] = useState(goalToRender.motivation);
  const [valuation, setValuation] = useState(() => {
    if (goalToRender.valuation === undefined || goalToRender.valuation === null) {
      return 0;
    }
    return goalToRender.valuation;
  });
  const [selectedSynergy, setSelectedSynergy] = useState(null);
  const [selectedConflict, setSelectedConflict] = useState(null);
  const setMotivationInSun = (e) => {
    setMotivation(e.target.value);
    const g = sun.goals.find((c) => c.mainGoalNumber === goalToRender.mainGoalNumber
      && c.subGoalNumber === goalToRender.subGoalNumber);
    g.motivation = e.target.value;
    const oldGoals = sun.sunGoals.filter((c) => c.id !== goalToRender.id);
    oldGoals.push(g);
    setSun({ ...sun, sunGoals: oldGoals });
  };

  const addAdminConflictToGoal = (fromGoal, conflictingGoal) => {
    setSun({
      ...sun,
      conflictsAndSynergies: sun.conflictsAndSynergies.concat({
        from: fromGoal, to: conflictingGoal, type: 1, sunId: sun.id, motivation: '',
      }),
    });
  };

  const addAdminSynergyToGoal = (fromGoal, synergeticGoal) => {
    setSun({
      ...sun,
      conflictsAndSynergies: sun.conflictsAndSynergies.concat({
        from: fromGoal, to: synergeticGoal, type: 2, sunId: sun.id, motivation: '',
      }),
    });
  };

  const handleValuationChange = (e) => {
    setValuation(Number(e.target.value));
    const g = sun.sunGoals.find((c) => c.id === goalToRender.id);
    g.valuation = Number(e.target.value);
    const oldGoals = sun.sunGoals.filter((c) => c.id !== goalToRender.id);
    oldGoals.push(g);
    setSun({ ...sun, sunGoals: oldGoals });
  };

  const handleDeleteConflict = (goalToRemove) => {
    setSun(
      {
        ...sun,
        conflictsAndSynergies: sun.conflictsAndSynergies.filter(
          (g) => g.to.id !== goalToRemove.id,
        ),
      },
    );
  };

  const handleAddConflictClick = (e) => {
    e.preventDefault();
    if (selectedConflict !== null && selectedConflict !== undefined) {
      addAdminConflictToGoal(goalToRender, selectedConflict);
    }
    typeaheadConflict.current.clear();
  };

  const handleAddConflict = (selected) => {
    if (selected[0]) {
      setSelectedConflict(selected[0]);
    }
  };

  const handleDeleteSynergy = (goalToRemove) => {
    setSun({
      ...sun,
      conflictsAndSynergies: sun.conflictsAndSynergies.filter(
        (g) => g.to.id !== goalToRemove.id,
      ),
    });
  };

  const handleAddSynergyClick = (e) => {
    e.preventDefault();
    if (selectedSynergy !== null && selectedSynergy !== undefined) {
      addAdminSynergyToGoal(goalToRender, selectedSynergy);
    }
    typeaheadSynergy.current.clear();
  };

  const handleAddSynergy = (selected) => {
    if (selected[0]) {
      setSelectedSynergy(selected[0]);
    }
  };

  return (
    <Row className="global-goal-target no-gutters">
      <Col className={`col-auto goal-bgcolor-${goalToRender.mainGoalNumber}`}>
        <Button variant="text" as="a" href={GlobalGoalMappingUrls[goalToRender.mainGoalNumber]} target="_blank" rel="noopener noreferrer" className={`btn-global-goal goal-bgcolor-${goalToRender.mainGoalNumber}`}>
          <figure>
            <figcaption>
              {goalToRender.mainGoalNumber}
              .
              {goalToRender.subGoalNumber}
            </figcaption>
            <img src={`/img/global-goal/targets/${goalToRender.mainGoalNumber}/${goalToRender.mainGoalNumber}.${goalToRender.subGoalNumber}.svg`} alt="" />
          </figure>
        </Button>
      </Col>
      <Col className="px-3 px-lg-5 pt-2 pt-lg-4">
        <Row className="d-flex align-items-stretch">
          <Col xs={12} sm={12} md={7} lg={8} className="pr-md-0 d-flex align-items-end">
            <Form.Group className="mb-1 mb-md-3 w-100">
              <label className="mr-3">{goalToRender.name}</label>
              <TextareaAutosize rows={1} value={motivation} onChange={(e) => setMotivationInSun(e)} className="form-control" placeholder={t('goalMotiveReasoning')} />
            </Form.Group>
          </Col>
          <Col xs={12} sm={6} md={5} lg={4} className="d-flex align-items-end">
            <div className="d-flex">
              <div className="w-100">
                <Form.Group>
                  <label>{t('goalRating')}</label>
                  <Form.Control as="select" value={valuation} onChange={(e) => handleValuationChange(e)}>
                    <option>-2</option>
                    <option>-1</option>
                    <option>0</option>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                  </Form.Control>
                </Form.Group>
              </div>
              <div className="pl-3 pt-5 pb-3">
                <ButtonGroup>
                  {(sun.conflictsAndSynergies.filter(
                    (conf) => conf.from.mainGoalNumber === goalToRender.mainGoalNumber
                      && conf.from.subGoalNumber === goalToRender.subGoalNumber
                      && conf.type === 1,
                  ).length > 0)
                    ? (
                      <Button variant="dark" onClick={() => setGoalEffectCollapse12(!goalEffectCollapse12)} aria-expanded={goalEffectCollapse12}>
                        <i className="fas fa-heart-broken fa-lg mr-0" />
                        <span className="sr-only">{t('conflicts')}</span>
                      </Button>
                    )
                    : (
                      <Button variant="secondary" onClick={() => setGoalEffectCollapse12(!goalEffectCollapse12)} aria-expanded={goalEffectCollapse12}>
                        <i className="fas fa-heart-broken fa-lg mr-0" />
                        <span className="sr-only">{t('conflicts')}</span>
                      </Button>
                    )}
                  {(sun.conflictsAndSynergies.filter(
                    (syn) => syn.from.mainGoalNumber === goalToRender.mainGoalNumber
                          && syn.from.subGoalNumber === goalToRender.subGoalNumber
                          && syn.type === 2,
                  ).length > 0)
                    ? (
                      <Button variant="warning" className="ml-0 border-left-0" onClick={() => setGoalEffectCollapse12(!goalEffectCollapse12)} aria-expanded={goalEffectCollapse12}>
                        <i className="far fa-heart fa-lg mr-0" />
                      </Button>
                    )
                    : (
                      <Button variant="secondary" className="ml-0 border-left-0" onClick={() => setGoalEffectCollapse12(!goalEffectCollapse12)} aria-expanded={goalEffectCollapse12}>
                        <i className="far fa-heart fa-lg mr-0" />
                      </Button>
                    )}

                </ButtonGroup>
              </div>
            </div>
          </Col>
        </Row>
        <Collapse in={goalEffectCollapse12} className="p-0" id="goalEffectCollapse12">

          <Row>
            <Col xs={12} sm={12} md={6} className="pr-md-2">
              <div className="goal-conflicts pt-2 pb-3 px-3 mb-3 mb-lg-5">
                <h5 className="mb-2">
                  <i className="fas fa-heart-broken fa-lg mr-2" />
                  {t('conflicts')}
                </h5>

                {/* Possible conflicts START */}
                <div className="mb-2">
                  <h6 className="mb-2">{t('conflictsPossible')}</h6>
                  {sun.adminConflictsAndSynergies.filter(
                    (conf) => conf.from.mainGoalNumber === goalToRender.mainGoalNumber
                      && conf.from.subGoalNumber === goalToRender.subGoalNumber
                      && conf.type === 1,
                  ).map((conf) => (
                    <>
                      <Row className="no-gutters" key={conf.id}>
                        <Col xs={2}>
                          <a href={GlobalGoalMappingUrls[conf.to.mainGoalNumber]} target="_blank" rel="noopener noreferrer" className={`badge goal-bgcolor-${conf.to.mainGoalNumber}`}>
                            {conf.to.mainGoalNumber}
                            .
                            {conf.to.subGoalNumber}
                          </a>
                        </Col>
                        <Col xs={11} sm={9}>
                          <p className="font-size-sm mt-1 mb-2">{conf.to.name}</p>
                        </Col>
                        <Col xs={1}>
                          <Button variant="text" className="btn-icon">
                            <i className="far fa-plus m-0" onClick={() => addAdminConflictToGoal(conf.from, conf.to)} />
                            <span className="sr-only">{t('add')}</span>
                          </Button>
                        </Col>
                      </Row>
                      <Row className="no-gutters">
                        <p>{conf.motivation}</p>
                      </Row>
                    </>
                  ))}
                </div>
                {/* Possible conflicts STOP */}
                {/* For each conflict START */}
                {sun.conflictsAndSynergies.filter((c) => c.type === 1
                  && c.from.mainGoalNumber === goalToRender.mainGoalNumber
                  && c.from.subGoalNumber === goalToRender.subGoalNumber)
                  .map((conflict) => (
                    <MiniConflictSynergy
                      key={conflict.id}
                      goalToRender={conflict.to}
                      handleDelete={handleDeleteConflict}
                      parentGoal={conflict}
                    />
                  ))}
                {/* For each conflict STOP */}
                <InputGroup className="flex-nowrap">
                  <Typeahead
                    className="w-100"
                    id="searchConflict1"
                    labelKey={(option) => `${option.mainGoalNumber}.${option.subGoalNumber} ${option.name}`}
                    options={allGoals.sort((a, b) => a.mainGoalNumber - b.mainGoalNumber)}
                    placeholder={t('goalTargetSearch')}
                    clearButton
                    onChange={handleAddConflict}
                    ref={typeaheadConflict}
                  />
                  <InputGroup.Append>
                    <Button variant="secondary">
                      <i className="far fa-plus fa-lg m-0" onClick={(handleAddConflictClick)} />
                      <span className="sr-only">{t('add')}</span>
                    </Button>
                  </InputGroup.Append>
                </InputGroup>

              </div>
            </Col>
            <Col xs={12} sm={12} md={6} className="pl-md-2">
              <div className="goal-synergies pt-2 pb-3 px-3 mb-3">
                <h5 className="mb-2">
                  <i className="far fa-heart fa-lg mr-2" />
                  {t('synergies')}
                </h5>

                {/* Possible synergy START */}
                <div className="mb-2">
                  <h6 className="mb-2">{t('synergiesPossible')}</h6>
                  {sun.adminConflictsAndSynergies.filter(
                    (syn) => syn.from.mainGoalNumber === goalToRender.mainGoalNumber
                      && syn.from.subGoalNumber === goalToRender.subGoalNumber
                      && syn.type === 2,
                  ).map((syn) => (
                    <Row className="no-gutters" key={syn.id}>
                      <Col xs={2}>
                        <a href={GlobalGoalMappingUrls[syn.to.mainGoalNumber]} target="_blank" rel="noopener noreferrer" className={`badge goal-bgcolor-${syn.to.mainGoalNumber}`}>
                          {syn.to.mainGoalNumber}
                          .
                          {syn.to.subGoalNumber}
                        </a>
                      </Col>
                      <Col xs={11} sm={9}>
                        <p className="font-size-sm mt-1 mb-2">{syn.to.name}</p>
                      </Col>
                      <Col xs={1}>
                        <Button variant="text" className="btn-icon" onClick={() => addAdminSynergyToGoal(syn.from, syn.to)}>
                          <i className="far fa-plus m-0" />
                          <span className="sr-only">{t('add')}</span>
                        </Button>
                      </Col>
                    </Row>
                  ))}
                </div>

                {sun.conflictsAndSynergies.filter((c) => c.type === 2
                  && c.from.mainGoalNumber === goalToRender.mainGoalNumber
                  && c.from.subGoalNumber === goalToRender.subGoalNumber)
                  .map((syn) => (
                    <MiniConflictSynergy
                      key={syn.id}
                      goalToRender={syn.to}
                      handleDelete={handleDeleteSynergy}
                      parentGoal={syn}
                    />
                  ))}
                <InputGroup className="flex-nowrap">
                  <Typeahead
                    className="w-100"
                    id="searchSynergy1"
                    labelKey={(option) => `${option.mainGoalNumber}.${option.subGoalNumber} ${option.name}`}
                    options={allGoals.sort((a, b) => a.mainGoalNumber - b.mainGoalNumber)}
                    placeholder={t('goalTargetSearch')}
                    clearButton
                    onChange={handleAddSynergy}
                    ref={typeaheadSynergy}
                  />
                  <InputGroup.Append>
                    <Button variant="secondary">
                      <i className="far fa-plus fa-lg m-0" onClick={handleAddSynergyClick} />
                      <span className="sr-only">{t('add')}</span>
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
              </div>
            </Col>
          </Row>

        </Collapse>
      </Col>
      <button type="button" className="close" onClick={() => handleDeleteGoal(goalToRender)} aria-label={t('delete')}><span>{t('delete')}</span></button>
    </Row>
  );
};

export default ProjectMiniGoal;
