import UserService from './UserService';
import AuthService from './AuthService';

class GoalService {
  constructor() {
    this.getInstance = AuthService.getInstance;
    this.postInstance = AuthService.postInstance;
    this.deleteInstance = AuthService.deleteInstance;
    this.putInstance = AuthService.putInstance;
  }

  async deleteAdminLevelConflictOrSynergy(from, to, type) {
    const requestData = {
      From: from,
      To: to,
      Type: type,
    };
    return this.postInstance.post(`${process.env.REACT_APP_API_URL}admin/delete`, requestData,
      { headers: { 'Content-Typ': 'application/json', Authorization: `Bearer ${UserService.getCurrentUser().token}` } })
      .then((res) => res.data).catch((err) => err);
  }

  async registerAdminNewConflictOrSynergy(conflictOrSynergy) {
    return this.postInstance.post(`${process.env.REACT_APP_API_URL}admin/add`, conflictOrSynergy,
      { headers: { 'Content-Typ': 'application/json', Authorization: `Bearer ${UserService.getCurrentUser().token}` } })
      .then((res) => res.data).catch((err) => err);
  }

  async syncAdminData(adminConflictsAndSynergies) {
    const requestData = {
      AdminConflictsAndSynergies: adminConflictsAndSynergies,
    };
    return this.postInstance.post(`${process.env.REACT_APP_API_URL}admin`, requestData,
      { headers: { 'Content-Typ': 'application/json', Authorization: `Bearer ${UserService.getCurrentUser().token}` } })
      .then((res) => res).catch((err) => err);
  }

  async getAdminData() {
    return this.getInstance.get(`${process.env.REACT_APP_API_URL}admin`,
      { headers: { Authorization: `Bearer ${UserService.getCurrentUser().token}` } })
      .then((res) => res.data).catch((err) => err);
  }

  async getAllGoals() {
    return this.getInstance.get(`${process.env.REACT_APP_API_URL}goals`,
      { headers: { Authorization: `Bearer ${UserService.getCurrentUser().token}` } })
      .then((res) => res.data).catch((err) => err);
  }

  async getAdminConflictsAndSynergies() {
    return this.getInstance.get(`${process.env.REACT_APP_API_URL}goals/admin`,
      { headers: { Authorization: `Bearer ${UserService.getCurrentUser().token}` } })
      .then((res) => res.data).catch((err) => err);
  }

  async getAdminConflictsForSpecificGoal(mainGoalNumber, subGoalNumber) {
    return this.getInstance.get(`${process.env.REACT_APP_API_URL}goals/admin/goal/conflicts?mainGoalNumber=${mainGoalNumber}&subGoalNumber=${subGoalNumber}`,
      { headers: { Authorization: `Bearer ${UserService.getCurrentUser().token}` } })
      .then((res) => res.data).catch((err) => err);
  }

  async getAdminSynergiesForSpecificGoal(mainGoalNumber, subGoalNumber) {
    return this.getInstance.get(`${process.env.REACT_APP_API_URL}goals/admin/goal/synergies?mainGoalNumber=${mainGoalNumber}&subGoalNumber=${subGoalNumber}`,
      { headers: { Authorization: `Bearer ${UserService.getCurrentUser().token}` } })
      .then((res) => res.data).catch((err) => err);
  }

  async registerSynergiesAndGoalsToAdminLevel(goalsToSendToAPI) {
    for (let i = 0; i < goalsToSendToAPI.length; i += 1) {
      const conflictsAlreadyRegisteredToGoal = await this.getAdminConflictsForSpecificGoal(
        goalsToSendToAPI[i].mainGoalNumber,
        goalsToSendToAPI[i].subGoalNumber,
      );
      const synergiesAlreadyReigsteredToGoal = await this.getAdminSynergiesForSpecificGoal(
        goalsToSendToAPI[i].mainGoalNumber,
        goalsToSendToAPI[i].subGoalNumber,
      );
      const conflictsToAdd = [];
      const conflictsToRemove = [];
      const synergiesToAdd = [];
      const synergiesToRemove = [];

      // Find the conflicts and synergies to add and remove.
      for (let k = 0; k < goalsToSendToAPI[i].conflicts.length; k += 1) {
        if (conflictsAlreadyRegisteredToGoal.filter(
          (c) => c.id === goalsToSendToAPI[i].conflicts[k].id,
        ).length === 0) { conflictsToAdd.push(goalsToSendToAPI[i].conflicts[k]); }
      }
      for (let k = 0; k < conflictsAlreadyRegisteredToGoal.length; k += 1) {
        if (goalsToSendToAPI[i].conflicts.filter(
          (c) => c.id === conflictsAlreadyRegisteredToGoal[k].to.id,
        ).length === 0) conflictsToRemove.push(conflictsAlreadyRegisteredToGoal[k].to);
      }

      for (let k = 0; k < goalsToSendToAPI[i].synergies.length; k += 1) {
        if (synergiesAlreadyReigsteredToGoal.filter(
          (c) => c.id === goalsToSendToAPI[i].synergies[k].id,
        ).length === 0) synergiesToAdd.push(goalsToSendToAPI[i].synergies[k]);
      }
      for (let k = 0; k < synergiesAlreadyReigsteredToGoal.length; k += 1) {
        if (goalsToSendToAPI[i].synergies.filter(
          (c) => c.id === synergiesAlreadyReigsteredToGoal[k].to.id,
        ).length === 0) synergiesToRemove.push(synergiesAlreadyReigsteredToGoal[k].to);
      }

      const from = {
        MainGoalNumber: Number(goalsToSendToAPI[i].mainGoalNumber),
        SubGoalNumber: goalsToSendToAPI[i].subGoalNumber,
      };

      for (let k = 0; k < conflictsToAdd.length; k += 1) {
        const to = {
          MainGoalNumber: Number(conflictsToAdd[k].mainGoalNumber),
          SubGoalNumber: conflictsToAdd[k].subGoalNumber,
        };
        const message = {
          From: from,
          To: to,
          ConflictOrSynergyType: 1,
        };
        this.postInstance.post(`${process.env.REACT_APP_API_URL}goals`,
          message,
          { headers: { crossDomain: true, 'Content-Type': 'application/json', Authorization: `Bearer  ${UserService.getCurrentUser().token}` } })
          .then((res) => res).catch((err) => err);
      }
      conflictsToRemove.map((conf) => {
        const to = {
          MainGoalNumber: Number(conf.mainGoalNumber),
          SubGoalNumber: conf.subGoalNumber,
        };
        const message = {
          From: from,
          To: to,
          ConflictOrSynergyType: 1,
        };
        this.postInstance.post(`${process.env.REACT_APP_API_URL}goals/delete`,
          message,
          { headers: { crossDomain: true, 'Content-Type': 'application/json', Authorization: `Bearer  ${UserService.getCurrentUser().token}` } })
          .then((res) => {
          }).catch((err) => err);
      });
      synergiesToAdd.map((syn) => {
        const to = {
          MainGoalNumber: Number(syn.mainGoalNumber),
          SubGoalNumber: syn.subGoalNumber,
        };
        const message = {
          From: from,
          To: to,
          ConflictOrSynergyType: 2,
        };
        return this.postInstance.post(`${process.env.REACT_APP_API_URL}goals`,
          message,
          { headers: { crossDomain: true, 'Content-Type': 'application/json', Authorization: `Bearer  ${UserService.getCurrentUser().token}` } })
          .then((res) => {
          }).catch((err) => err);
      });
      synergiesToRemove.map((syn) => {
        const to = {
          MainGoalNumber: Number(syn.mainGoalNumber),
          SubGoalNumber: syn.subGoalNumber,
        };
        const message = {
          From: from,
          To: to,
          ConflictOrSynergyType: 2,
        };
        this.postInstance.post(`${process.env.REACT_APP_API_URL}goals/delete`,
          message,
          { headers: { crossDomain: true, 'Content-Type': 'application/json', Authorization: `Bearer  ${UserService.getCurrentUser().token}` } })
          .then((res) => {
          }).catch((err) => err);
      });
    }
  }
}

export default new GoalService();
