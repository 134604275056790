import React, { useState, useContext } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import ProjectService from '../services/ProjectService';

import { ProjectContext } from '../contexts/ProjectContext';
import { SunContext } from '../contexts/SunContext';

function ProjectModal({
  open, onHide, isEdit, name, id,
}) {
  const history = useHistory();
  const { t } = useTranslation();

  const [sun, setSun] = useContext(SunContext);
  const [project, setProject] = useContext(ProjectContext);
  const [projectName, setProjectName] = useState(() => { if (isEdit) { return name; } return ''; });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const res = await ProjectService.registerProject(projectName);
    setProject(res);
    onHide();
    setSun(null);
    history.push({
      pathname: '/project',
      state: res,
    });
  };

  const editProject = async (e) => {
    e.preventDefault();
    onHide();
    const proj = await ProjectService.editProject(id, projectName);
    setProject(proj.data);
  };

  const onDeleteClick = async (e) => {
    e.preventDefault();
    onHide();
    await ProjectService.deleteProject(id);
    history.push({
      pathname: '/',
    });
  };

  if (isEdit) {
    return (
      <>
        <Modal show={open} onHide={onHide}>
          <Modal.Header>
            <Modal.Title>{t('project')}</Modal.Title>
            <Button variant="text" className="close" onClick={onHide} aria-label={t('close')}><span>{t('close')}</span></Button>
          </Modal.Header>
          <Modal.Body className="p-0">
            <fieldset className="pt-3 pb-5 m-0">
              <Form.Group>
                <Form.Label>{t('projectName')}</Form.Label>
                <Form.Control type="text" placeholder={t('name')} value={projectName} onChange={(e) => setProjectName(e.target.value)} />
              </Form.Group>
            </fieldset>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={onDeleteClick}>{t('delete')}</Button>
            <Button variant="secondary" onClick={onHide}>{t('cancel')}</Button>
            <Button variant="primary" onClick={editProject}>{t('save')}</Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  return (
    <>
      <Modal show={open} onHide={onHide}>
        <Modal.Header>
          <Modal.Title>{t('project')}</Modal.Title>
          <Button variant="text" className="close" onClick={onHide} aria-label={t('close')}><span>{t('close')}</span></Button>
        </Modal.Header>
        <Modal.Body className="p-0">
          <fieldset className="pt-3 pb-5 m-0">
            <Form.Group>
              <Form.Label>{t('projectName')}</Form.Label>
              <Form.Control type="text" placeholder={t('name')} onChange={(e) => setProjectName(e.target.value)} />
            </Form.Group>
          </fieldset>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>{t('cancel')}</Button>
          <Button variant="primary" onClick={handleSubmit}>{t('add')}</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ProjectModal;
