import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from 'i18next-xhr-backend';

import translationEng from './locales/en/translation.json';
import translationSwe from './locales/sv/translation.json';
import translationDk from './locales/dk/translation.json';

i18n
  .use(XHR)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'en', // use en if detected lng is not available
    keySeparator: false, // we do not use keys in form messages.welcome
    react: {
      useSuspense: false,
    },
    interpolation: {
      escapeValue: false, // react already safes from xss
    },

    resources: {
      en: {
        translations: translationEng,
      },
      sv: {
        translations: translationSwe,
      },
      dk: {
        translations: translationDk,
      },
    },
    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',
  });

export default i18n;
