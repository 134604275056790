import React, { useContext, useState, useEffect } from 'react';
import { Accordion } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import AccordionContext from 'react-bootstrap/AccordionContext';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';

import { SunContext } from '../contexts/SunContext';
import SummaryGoal from './SummaryGoal';

function AccordionAwareToggle({ children, eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext);
  const expandedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey),
  );

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <button
      type="button"
      className="btn btn-text btn-collapse btn-block text-white text-left d-flex"
      aria-expanded={isCurrentEventKey ? 'true' : 'false'}
      onClick={expandedOnClick}
    >
      {children}
    </button>
  );
}

function SummaryAccordion() {
  const [sun, setSun] = useContext(SunContext);

  const getMainGoalsToRender = () => {
    const baseGoals = sun.goals.filter((c) => c.subGoalNumber === '0');
    const baseGoalsToRenderMap = new Map();
    for (let i = 0; i < baseGoals.length; i += 1) {
      baseGoalsToRenderMap.set(baseGoals[i].mainGoalNumber, ({ goal: baseGoals[i], shouldRender: false }));
    }
    for (let i = 0; i < sun.sunGoals.length; i += 1) {
      const oldGoal = baseGoalsToRenderMap.get(sun.sunGoals[i].mainGoalNumber).goal;
      baseGoalsToRenderMap.set(sun.sunGoals[i].mainGoalNumber, ({ goal: oldGoal, shouldRender: true }));
    }
    for (const [k, val] of baseGoalsToRenderMap) {
      if (val.shouldRender === false) {
        baseGoalsToRenderMap.delete(k);
      }
    }
    const goals = [];
    for (const val of baseGoalsToRenderMap.values()) {
      goals.push(val.goal);
    }
    return goals;
  };

  useEffect(() => {
    setBaseGoalsToRender(getMainGoalsToRender());
  }, [sun.sunGoals]);

  const [baseGoalsToRender, setBaseGoalsToRender] = useState(() => getMainGoalsToRender());
  const { t } = useTranslation();

  return (
    <>
      <h4>{t('summary')}</h4>
      <Accordion className="summary-accordion">
        {sun.goals ? baseGoalsToRender.sort(
          (a, b) => a.mainGoalNumber - b.mainGoalNumber,
        ).map((goal, index) => (
          <div className="mb-1" key={goal.id}>
            <div className={`accordion-header goal-bgcolor-${goal.mainGoalNumber}`}>
              <AccordionAwareToggle eventKey={`${index}`}>
                <span className="mr-2">
                  {goal.mainGoalNumber}
                </span>
                <span className="text-wrap">{goal.name}</span>
                <div className="goal-effect">
                  {sun.conflictsAndSynergies.filter((c) => c.type === 1
                    && c.from.mainGoalNumber === goal.mainGoalNumber).length > 0
                    ? (
                      <div className="effect-conflicts d-block">
                        <i className="fas fa-heart-broken" />
                        <span className="sr-only">{t('conflicts')}</span>
                      </div>
                    )
                    : <></>}
                  {sun.conflictsAndSynergies.filter((c) => c.type === 2
                    && c.from.mainGoalNumber === goal.mainGoalNumber).length > 0
                    ? (
                      <div className="effect-synergies d-block">
                        <i className="far fa-heart" />
                        <span className="sr-only">{t('synergies')}</span>
                      </div>
                    )
                    : <></>}
                </div>
              </AccordionAwareToggle>
            </div>

            <Accordion.Collapse eventKey={`${index}`}>
              <div className="pt-3">
                <SummaryGoal goal={goal} />
              </div>
            </Accordion.Collapse>
          </div>
        )) : <></>}
      </Accordion>
    </>
  );
}

export default SummaryAccordion;
