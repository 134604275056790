import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import AdminGlobalGoalModal from './AdminGlobalGoalModal';

const Goal = ({ number, goals, conflictsAndSynergies }) => {
  const { t } = useTranslation();
  const [adminModalGlobalGoalOpen, setAdminModalGlobalGoalOpen] = useState(false);
  const handleClick = (e) => {
    e.preventDefault();
    setAdminModalGlobalGoalOpen(true);
  };

  return (
    <>
      <Button variant="text" id={`btn-goal-${number}`} onClick={handleClick}>
        <img src={`/img/global-goal/${number}_${t('lang')}.svg`} alt="" />
      </Button>
      {adminModalGlobalGoalOpen
                && (
                <AdminGlobalGoalModal
                  open={adminModalGlobalGoalOpen}
                  onHide={() => setAdminModalGlobalGoalOpen(false)}
                  data={goals}
                  number={number}
                  conflictsAndSynergies={conflictsAndSynergies}
                />
                )}
    </>
  );
};

export default Goal;
