import React, { useContext } from 'react';
import {
  Navbar, Container, Row, Col, Button,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import html2canvas from 'html2canvas';
import { Link } from 'react-router-dom';
import { SunContext } from '../contexts/SunContext';

function NavBarExport() {
  const { t } = useTranslation();
  const [sun, setSun] = useContext(SunContext);

  function saveAs(uri, filename) {
    const link = document.createElement('a');

    if (typeof link.download === 'string') {
      link.href = uri;
      link.download = filename;

      // Firefox requires the link to be in the body
      document.body.appendChild(link);

      // simulate click
      link.click();

      // remove the link when done
      document.body.removeChild(link);
    } else {
      window.open(uri);
    }
  }

  const prepImage = () => {
    window.scrollTo(0, 0);
    const node = document.querySelector('#global-sun');
    html2canvas(node, { scrollX: -25, scrollY: -window.scrollY, scale: 0.94 }).then((canvas) => {
      saveAs(canvas.toDataURL(), 'sun.png');
    });
  };

  const downloadImage = () => {
    prepImage();
  };

  return (
    <Navbar
      expand="lg"
      fixed="bottom"
      className="navbar-south fade show"
      role="navigation"
    >
      <Container id="navbarExport">
        <Row noGutters>
          <Navbar.Toggle
            aria-controls="navbarSouthNav"
            aria-label={t('toggleNav')}
          >
            <span className="btn-nav-box">
              <span className="btn-nav-inner" />
            </span>
          </Navbar.Toggle>

          <Col>
            <Navbar.Collapse
              id="navbarSouthNav"
              className="justify-content-around"
            >
              {sun && (
                <div className="navbar-nav mt-3 mt-lg-2">
                  <ul className="navbar-nav nav">
                    <li className="nav-item">
                      <label htmlFor="export-image" className="nav-link pointer-click-events-none">
                        {t('export')}
                      </label>
                      <Button id="export-image" className="nav-link" onClick={downloadImage}>
                        <i className="fal fa-image fal-lg" />
                        {t('image')}
                      </Button>
                    </li>
                  </ul>
                </div>
              )}
              <div className="navbar-nav mt-3 mt-lg-2">
                <ul className="navbar-nav nav">
                  <li className="nav-item">
                    <Link
                      to="/datapolicy"
                      className="nav-link"
                    >
                      DATA PRIVACY
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/cookiepolicy"
                      className="nav-link"
                    >
                      COOKIE POLICY
                    </Link>
                  </li>
                </ul>
              </div>
            </Navbar.Collapse>
          </Col>
        </Row>
      </Container>
    </Navbar>
  );
}

export default NavBarExport;
