import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useCookies } from 'react-cookie';
import { Link } from 'react-router-dom';

const buttonText = 'I understand';

const bannerMessage = `We use cookies on this website which are necessary for 
  the website to function and cannot be switched off in our systems. You can 
  set your browser to block or alert you about these cookies, but some parts 
  of the site might not then work. These cookies do not store any personally 
  identifiable information. `;

const titleText = 'Cookie Consent';

export default () => {
  const [cookies, setCookie] = useCookies(['cookie-consent']);

  const [modalOpen, setModalOpen] = React.useState(!cookies['cookie-consent']);

  const acceptCookies = () => {
    const timestamp = new Date().getTime();
    const expire = timestamp + (60 * 60 * 24 * 1000 * 180);
    // 60 minutes * 60 seconds * 24 hours * 1000 (for milliseconds) * 180 days
    // expiry date = 180 days

    const expireDate = new Date(expire);

    setCookie('cookie-consent', true, { path: '/', expires: expireDate });
    setModalOpen(false);
  };

  return (
    <Modal show={modalOpen}>
      <Modal.Header>
        <Modal.Title>{titleText}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {bannerMessage}
        <br />
        <br />
        To read more, please visit either
        {' '}
        <Link className="text-nowrap" to="/cookiepolicy">
          Cookie Policy
        </Link>
        {' '}
        or
        {' '}
        <Link className="text-nowrap" to="/datapolicy">
          Data Policy
        </Link>
        .
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={acceptCookies}>
          {buttonText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
