import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import NavBarExport from '../components/NavBarExport';

export default function DataPolicy() {
  const { t } = useTranslation();

  return (
    <>
      <Container className="white py-3 mt-lg-5 mb-5">
        <Row>
          <Col>
            <h1 className="h2">{t('dataPolicyHeader1')}</h1>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={8}>
            <p>{t('dataPolicyLead1')}</p>
            <h3>{t('dataPolicyHeader2')}</h3>
            <p>{t('dataPolicyLead2')}</p>
            <h3>{t('dataPolicyHeader3')}</h3>
            <p>{t('dataPolicyLead3')}</p>
            <ul>
              <li>
                {t('dataPolicyLead3List1')}
              </li>
            </ul>
            <h3>{t('dataPolicyHeader4')}</h3>
            <ul>
              <li>
                {t('dataPolicyLead4List1')}
                {' '}
                <a href="mailto:localprivacyofficer@sweco.se">localprivacyofficer@sweco.se</a>
              </li>
            </ul>
            <h3>{t('dataPolicyHeader5')}</h3>
            <p>{t('dataPolicyLead5')}</p>
            <ul>
              <li>
                {t('dataPolicyLead5List1')}
              </li>
            </ul>

            <h3>{t('dataPolicyHeader6')}</h3>
            <p>{t('dataPolicyLead6')}</p>
            <ul>
              <li>
                {t('dataPolicyLead6List1')}
              </li>
            </ul>
            <ul>
              <li>
                {t('dataPolicyLead6List2')}
              </li>
            </ul>
            <ul>
              <li>
                {t('dataPolicyLead6List3')}
              </li>
            </ul>
            <h3>{t('dataPolicyHeader7')}</h3>
            <p>{t('dataPolicyLead7')}</p>
            <h3>{t('dataPolicyHeader8')}</h3>
            <p>{t('dataPolicyLead8')}</p>
            <h3>{t('dataPolicyHeader9')}</h3>
            <p>{t('dataPolicyLead9')}</p>
            <h3>{t('dataPolicyHeader10')}</h3>
            <p>{t('dataPolicyLead10')}</p>
            <ul>
              <li>
                {t('dataPolicyLead10List1')}
              </li>
              <li>
                {t('dataPolicyLead10List2')}
              </li>
              <li>
                {t('dataPolicyLead10List3')}
              </li>
              <li>
                {t('dataPolicyLead10List4')}
              </li>
              <li>
                {t('dataPolicyLead10List5')}
              </li>
              <li>
                {t('dataPolicyLead10List6')}
              </li>
              <li>
                {t('dataPolicyLead10List7')}
              </li>
            </ul>
            <p>
              {t('dataPolicyLead11')}
              {' '}
              <a href="mailto:localprivacyofficer@sweco.se">localprivacyofficer@sweco.se</a>
              .
              {' '}
              {t('dataPolicyLead12')}
              {' '}
              <a href="mailto:groupprivacyofficer@sweco.se">groupprivacyofficer@sweco.se</a>
            </p>
          </Col>
          <Col xs={12} md={4} className="p-5">
            <img src="/img/17-global.svg" alt="" />
          </Col>
        </Row>
      </Container>
      <NavBarExport />
    </>
  );
}
