import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export default function About() {
  const { t } = useTranslation();

  return (
    <>
      <Container className="white py-3 mt-lg-5 mb-5">
        <Row>
          <Col>
            <h1 className="h2">{t('aboutHeader')}</h1>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={8}>
            <h3>{t('about1header')}</h3>
            <p>{t('about1lead')}</p>
            <h3>{t('about2header')}</h3>
            <p>{t('about2lead')}</p>
            <h3>{t('about3header')}</h3>
            <p>{t('about3lead')}</p>
            <h3>{t('about4header')}</h3>
            <p>{t('about4lead')}</p>
            <h3>{t('about5header')}</h3>
            <p>{t('about5lead')}</p>
            <h3>{t('about6header')}</h3>
            <p>{t('about6lead')}</p>
          </Col>
          <Col xs={12} md={4} className="p-5">
            <img src="/img/17-global.svg" alt="" />
          </Col>
        </Row>
      </Container>
    </>
  );
}
