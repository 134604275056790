import React, { useState, useContext, useEffect } from 'react';
import {
  Button, Alert, Row, Col, Modal, Form,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import UserService from '../services/UserService';
import { UserContext } from '../contexts/UserContext';

function SignInModal({ open, onHide, isSignUp }) {
  const { t } = useTranslation();
  const [userForm, setUserForm] = useState({
    Email: '', Password: '', FirstName: '', LastName: '',
  });
  const [showAlert, setShowAlert] = useState(false);
  const [showDangerAlert, setShowDangerAlert] = useState(false);
  const [user, setUser] = useContext(UserContext);
  const [completedLogin, setCompletedLogin] = useState(true);
  const [userExists, setUserExists] = useState(true);

  function onSignUpClick() {
    const formSignIn = document.getElementById('formSignIn');
    const formSignUp = document.getElementById('formSignUp');
    formSignIn.classList.add('d-none');
    formSignUp.classList.remove('d-none');
  }

  useEffect(() => {
    if (isSignUp) {
      onSignUpClick();
    }
  }, []);

  const handleRegisterSubmit = async (e) => {
    e.preventDefault();
    await UserService.register(userForm);
    onHide();
  };

  const handleSignInSubmit = async (e) => {
    setCompletedLogin(true);
    setUserExists(true);
    e.preventDefault();
    const registeredUser = await UserService.login(userForm);
    if (registeredUser.jwtToken !== undefined) {
      setCompletedLogin(true);
      setUserExists(true);
      setUser(registeredUser);
      window.location.reload(); // Ugly fix for loading admin settings.
      onHide();
    } else if (registeredUser.status === 401) {
      setCompletedLogin(false);
    } else if (registeredUser.status === 404) {
      setUserExists(false);
    }
  };

  const onResetPasswordClick = () => {
    const formSignIn = document.getElementById('formSignIn');
    const formSignUp = document.getElementById('formSignUp');
    const formPasswordReset = document.getElementById('formPasswordReset');
    formSignIn.classList.add('d-none');
    formSignUp.classList.add('d-none');
    formPasswordReset.classList.remove('d-none');
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    const res = await UserService.passwordResetEmail(userForm.Email);
    const alertDiv = document.getElementById('alertDiv');
    alertDiv.classList.remove('d-none');
    if (res) {
      const formPasswordReset = document.getElementById('formPasswordReset');
      formPasswordReset.classList.add('d-none');
      setShowDangerAlert(false);
      setShowAlert(true);
    } else {
      setShowDangerAlert(true);
    }
  };

  return (
    <>
      {/* Sign in form */}
      <Modal show={open} onHide={onHide}>
        <div className="d-none" id="alertDiv">
          <Alert variant="success" show={showAlert}>
            Email successfully sent! Please check your email for verification code and link!
          </Alert>
          <Alert variant="danger" show={showDangerAlert} onClose={() => setShowDangerAlert(false)} dismissible>
            Something went wrong trying to reset your password. Double check that your email is correct or contact an admin
          </Alert>
        </div>
        <Form id="formSignIn" className="d-show" onSubmit={handleSignInSubmit}>
          <Modal.Header>
            <Modal.Title>{t('signIn')}</Modal.Title>
            <Button variant="text" className="close" onClick={onHide} aria-label={t('close')}><span>{t('close')}</span></Button>
          </Modal.Header>
          <Modal.Body>
            <fieldset className="p-3 mb-0">
              <Form.Group controlId="formSignInEmail">
                <Form.Label>{t('email')}</Form.Label>
                <Form.Control type="email" required placeholder={t('emailEnter')} onChange={(e) => setUserForm({ ...userForm, Email: e.target.value })} />
              </Form.Group>
              <Form.Group controlId="formSignInPassword">
                <Form.Label>{t('password')}</Form.Label>
                <Form.Control type="password" required placeholder={t('password')} onChange={(e) => setUserForm({ ...userForm, Password: e.target.value })} />

                <Form.Text className="text-muted">
                  <Button onClick={onResetPasswordClick}>{t('passwordForgot')}</Button>
                </Form.Text>

                {!completedLogin && <p>Login failed. Please check credentials.</p>}
                {!userExists && <p>Login failed. User doesn't exist. Please register first before logging in.</p>}
              </Form.Group>
              <Row>
                <Col className="text-center">
                  <Button variant="text" className="btn-link text-nowrap" onClick={onSignUpClick}>
                    <i className="far fa-user-plus mr-2" />
                    {t('register')}
                  </Button>
                </Col>
              </Row>
            </fieldset>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={onHide}>{t('cancel')}</Button>
            <Button variant="primary" type="submit">
              <i className="far fa-sign-in" />
              {t('signIn')}
            </Button>
          </Modal.Footer>
        </Form>

        {/* Sign Up form */}
        <Form id="formSignUp" className="d-none" onSubmit={handleRegisterSubmit}>
          <Modal.Header>
            <Modal.Title>{t('register')}</Modal.Title>
            <Button variant="text" className="close" onClick={onHide} aria-label={t('close')}><span>{t('close')}</span></Button>
          </Modal.Header>
          <Modal.Body>
            <fieldset className="p-3 mb-0">
              <Form.Group controlId="formSignUpName">
                <Form.Label>{t('firstname')}</Form.Label>
                <Form.Control
                  type="name"
                  placeholder={t('firstname')}
                  onChange={(e) => setUserForm({ ...userForm, FirstName: e.target.value })}
                />
              </Form.Group>
              <Form.Group controlId="formSignUpName">
                <Form.Label>{t('lastname')}</Form.Label>
                <Form.Control
                  type="name"
                  placeholder={t('lastname')}
                  onChange={(e) => setUserForm({ ...userForm, LastName: e.target.value })}
                />
              </Form.Group>
              <Form.Group controlId="formSignUpEmail">
                <Form.Label>{t('email')}</Form.Label>
                <Form.Control type="email" placeholder={t('emailEnter')} onChange={(e) => setUserForm({ ...userForm, Email: e.target.value })} />
              </Form.Group>
              <Form.Group controlId="formSignUpPassword">
                <Form.Label>{t('password')}</Form.Label>
                <Form.Control type="password" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" required placeholder={t('passwordEnter')} onChange={(e) => setUserForm({ ...userForm, Password: e.target.value })} />
                <Form.Text className="text-muted">
                  {t('passwordHelp')}
                </Form.Text>
              </Form.Group>
            </fieldset>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={onHide}>{t('cancel')}</Button>
            <Button variant="primary" type="submit">
              <i className="far fa-user-plus" />
              {t('signUp')}
            </Button>
          </Modal.Footer>
        </Form>
        {/* Forgot password form */}
        <Form id="formPasswordReset" className="d-none" onSubmit={handleResetPassword}>
          <Modal.Header>
            <Modal.Title>{t('register')}</Modal.Title>
            <Button variant="text" className="close" onClick={onHide} aria-label={t('close')}><span>{t('close')}</span></Button>
          </Modal.Header>
          <Modal.Body>
            <fieldset className="p-3 mb-0">
              <Form.Group controlId="formSignUpEmail">
                <Form.Label>{t('email')}</Form.Label>
                <Form.Control type="email" placeholder={t('email')} onChange={(e) => setUserForm({ ...userForm, Email: e.target.value })} />
                <Form.Text className="text-muted">
                  {t('emailReset')}
                  {' '}
                </Form.Text>
              </Form.Group>
            </fieldset>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={onHide}>{t('cancel')}</Button>
            <Button variant="primary" type="submit">
              <i className="far fa-unlock" />
              {t('passwordReset')}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export default SignInModal;
