import React, { useState, useContext } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import SunService from '../services/SunService';

import { SunContext } from '../contexts/SunContext';

function SunModal({
  open, onHide, projectId, isEdit, setSuns, newSun,
}) {
  const history = useHistory();
  const { t } = useTranslation();

  const [sun, setSun] = useContext(SunContext);
  const [sunName, setSunName] = useState(() => { if (isEdit) { return sun.name; } return ''; });
  const [sunDescription, setSunDescription] = useState(() => { if (isEdit) { return sun.description; } return ''; });

  const handleSubmit = async (e) => {
    e.preventDefault();
    onHide();
    const res = await SunService.registerNewSun(sunName, sunDescription, projectId);
    setSun(res.data);
    newSun(res.data);
  };

  const editSun = async (e) => {
    e.preventDefault();
    onHide();
    const res = await SunService.editSun(sunName, sunDescription, sun.id);
    setSun(res.data);
  };

  const onDeleteClick = async (e) => {
    e.preventDefault();
    onHide();
    await SunService.deleteSun(sun.id);
    history.push({
      pathname: '/project',
      state: { id: projectId },
    });
    setSuns(null);
    setSun(null);
  };

  if (isEdit) {
    return (
      <>
        <Modal show={open} onHide={onHide}>
          <Modal.Header>
            <Modal.Title>{t('sun')}</Modal.Title>
            <Button variant="text" className="close" onClick={onHide} aria-label={t('close')}><span>{t('close')}</span></Button>
          </Modal.Header>
          <Modal.Body className="p-0">
            <fieldset className="py-3 m-0">
              <Form.Group controlId="sunName">
                <Form.Label>{t('sunName')}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t('name')}
                  value={sunName}
                  onChange={(e) => setSunName(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="sunSummary">
                <Form.Label>
                  {t('sunSummaryLabel')}
                  {' '}
                  *
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows="4"
                  placeholder={t('summary')}
                  value={sunDescription}
                  onChange={(e) => setSunDescription(e.target.value)}
                />
                <small className="form-text">
                  *
                  {' '}
                  {t('sunSummaryHelp')}
                </small>
              </Form.Group>
            </fieldset>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={onDeleteClick}>{t('delete')}</Button>
            <Button variant="secondary" onClick={onHide}>{t('cancel')}</Button>
            <Button variant="primary" type="submit" onClick={editSun}>{t('save')}</Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  return (
    <>
      <Modal show={open} onHide={onHide}>
        <Modal.Header>
          <Modal.Title>{t('sun')}</Modal.Title>
          <Button variant="text" className="close" onClick={onHide} aria-label={t('close')}><span>{t('close')}</span></Button>
        </Modal.Header>
        <Modal.Body className="p-0">
          <fieldset className="py-3 m-0">
            <Form.Group controlId="sunName">
              <Form.Label>{t('sunName')}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t('name')}
                value={sunName}
                onChange={(e) => setSunName(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="sunSummary">
              <Form.Label>
                {t('sunSummaryLabel')}
                {' '}
                *
              </Form.Label>
              <Form.Control
                as="textarea"
                rows="4"
                placeholder={t('summary')}
                value={sunDescription}
                onChange={(e) => setSunDescription(e.target.value)}
              />
              <small className="form-text">
                *
                {' '}
                {t('sunSummaryHelp')}
              </small>
            </Form.Group>
          </fieldset>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>{t('cancel')}</Button>
          <Button variant="primary" type="submit" onClick={handleSubmit}>{t('save')}</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SunModal;
