import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import NavBarExport from '../components/NavBarExport';

export default function CookiePolicy() {
  const { t } = useTranslation();

  return (
    <>
      <Container className="white py-3 mt-lg-5 mb-5">
        <Row>
          <Col>
            <h1 className="h2">{t('cookiePolicyheader1')}</h1>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={8}>
            <p>{t('cookiePolicylead1')}</p>
            <p>{t('cookiePolicylead2')}</p>
            <h3>{t('cookiePolicyheader2')}</h3>
            <p>{t('cookiePolicylead3')}</p>
            <ul>
              <li>
                <a href="https://cookiepedia.co.uk/cookies/arraffinity">ARRAffinity</a>
                :
                {' '}
                {t('cookiePolicylead4')}
              </li>
              <li>
                <a href="https://cookiepedia.co.uk/cookies/arraffinitysamesite">ARRAffinitySameSite</a>
                :
                {' '}
                {t('cookiePolicylead5')}
              </li>
            </ul>
          </Col>
          <Col xs={12} md={4} className="p-5">
            <img src="/img/17-global.svg" alt="" />
          </Col>
        </Row>
      </Container>
      <NavBarExport />
    </>
  );
}
