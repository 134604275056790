import React, { useState, createContext } from 'react';
import PropTypes from 'prop-types';

export const AdminContext = createContext();

export const AdminProvider = ({ children }) => {
  const [adminData, setAdminData] = useState();
  return (
    <AdminContext.Provider value={[adminData, setAdminData]}>
      {children}
    </AdminContext.Provider>
  );
};

AdminProvider.propTypes = {
  children: PropTypes.element.isRequired,
};
