import Cookies from 'js-cookie';
import AuthService from './AuthService';

class UserService {
  constructor() {
    this.getInstance = AuthService.getInstance;
    this.postInstance = AuthService.postInstance;
    this.deleteInstance = AuthService.deleteInstance;
    this.putInstance = AuthService.putInstance;
  }

  checkExpiredToken() {
    const user = this.getCurrentUser();
    if (user !== undefined && user !== null) {
      const currentTime = Date.now();
      return Math.abs(currentTime - user.time) / 36e5;
    }
  }

  async login(user) {
    return this.postInstance.post(`${process.env.REACT_APP_API_URL}users/authenticate`,
      {
        Email: user.Email,
        Password: user.Password,
      },
      { headers: { crossDomain: true, 'Content-Type': 'application/json' } })
      .then((res) => {
        if (res.data.jwtToken) {
          localStorage.setItem('user', JSON.stringify({
            token: res.data.jwtToken,
            name: res.data.username,
            isAdmin: res.data.isAdmin,
            time: Date.now(),
          }));
        }
        return res.data;
      }).catch((err) => err.response);
  }

  async register(user) {
    return this.postInstance.post(`${process.env.REACT_APP_API_URL}users/`, {
      FirstName: user.FirstName,
      LastName: user.LastName,
      Email: user.Email,
      Password: user.Password,
    }, { headers: { 'Content-Type': 'application/json' } })
      .catch((err) => err);
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));
  }

  refreshToken() {
    if (localStorage.getItem('user')) {
      const refToken = Cookies.get('refreshToken');
      return this.postInstance.post(`${process.env.REACT_APP_API_URL}users/refresh-token`,
        { headers: { crossDomain: true, Authorization: `Bearer ${refToken}` } })
        .then((res) => {
          localStorage.setItem('user', JSON.stringify({
            token: res.data.jwtToken,
            name: res.data.username,
            isAdmin: res.data.isAdmin,
            time: Date.now(),
          }));
          return res.data;
        }).catch((err) => err);
    }
    return null;
  }

  logout() {
    localStorage.removeItem('user');
    Cookies.remove('refreshToken');
  }

  async passwordResetEmail(email) {
    return this.postInstance.post(`${process.env.REACT_APP_API_URL}users/password/reset?emailAddress=${email}`,
      { headers: { 'Content-Type': 'application/json' } })
      .then((res) => {
        if (res.status === 200) {
          return true;
        }
        return false;
      })
      .catch((err) => {
        console.log(err);
        return false;
      });
  }

  async passwordReset(email, newPassword, verificationCode) {
    return this.postInstance.post(`${process.env.REACT_APP_API_URL}users/password/new`,
      {
        VerificationCode: verificationCode,
        NewPassword: newPassword,
        Email: email,
      },
      { headers: { 'Content-Type': 'application/json' } })
      .then((res) => {
        if (res.status === 200) {
          return true;
        }
        return false;
      })
      .catch((err) => {
        console.log(err);
        return false;
      });
  }
}

export default new UserService();
