import UserService from './UserService';
import AuthService from './AuthService';

class SunService {
  constructor() {
    this.getInstance = AuthService.getInstance;
    this.postInstance = AuthService.postInstance;
    this.deleteInstance = AuthService.deleteInstance;
    this.putInstance = AuthService.putInstance;
  }

  async registerNewSun(sunName, sunDescription, projectId) {
    return this.postInstance.post(`${process.env.REACT_APP_API_URL}sun`,
      { SunName: sunName, SunDescription: sunDescription, ProjectId: projectId },
      { headers: { 'Content-Typ': 'application/json', Authorization: `Bearer ${UserService.getCurrentUser().token}` } })
      .then((res) => res).catch((err) => err);
  }

  async editSun(sunName, sunDescription, sunId) {
    return this.putInstance.put(`${process.env.REACT_APP_API_URL}sun`,
      { SunName: sunName, SunDescription: sunDescription, SunId: sunId },
      { headers: { 'Content-Typ': 'application/json', Authorization: `Bearer ${UserService.getCurrentUser().token}` } })
      .then((res) => res).catch((err) => err);
  }

  async deleteSun(sunId) {
    return this.deleteInstance.delete(`${process.env.REACT_APP_API_URL}sun/${sunId}`,
      { headers: { 'Content-Typ': 'application/json', Authorization: `Bearer ${UserService.getCurrentUser().token}` } })
      .then((res) => res).catch((err) => err);
  }

  async getSunInformation(sunId) {
    return this.getInstance.get(`${process.env.REACT_APP_API_URL}sun/${sunId}`,
      { headers: { 'Content-Typ': 'application/json', Authorization: `Bearer ${UserService.getCurrentUser().token}` } })
      .then((res) => res).catch((err) => err);
  }

  async syncSunData(selectedSun) {
    const requestData = {
      Id: selectedSun.id,
      ProjectId: selectedSun.projectId,
      Name: selectedSun.name,
      Description: selectedSun.description,
      ConflictsAndSynergies: selectedSun.conflictsAndSynergies,
      SunGoals: selectedSun.sunGoals,
    };
    return this.postInstance.post(`${process.env.REACT_APP_API_URL}sun/goals`, requestData,
      { headers: { 'Content-Typ': 'application/json', Authorization: `Bearer ${UserService.getCurrentUser().token}` } })
      .then((res) => res).catch((err) => err);
  }

  async syncAdminData(adminConflictsAndSynergies) {
    const requestData = {
      AdminConflictsAndSynergies: adminConflictsAndSynergies,
    };
    return this.postInstance.post(`${process.env.REACT_APP_API_URL}admin`, requestData,
      { headers: { 'Content-Typ': 'application/json', Authorization: `Bearer ${UserService.getCurrentUser().token}` } })
      .then((res) => res).catch((err) => err);
  }
}

export default new SunService();
