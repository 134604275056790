import React, { useState, useEffect, useContext } from 'react';
import {
  Container, Row, Col,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Goal from '../components/Goal';

import GoalService from '../services/GoalService';
import { AdminContext } from '../contexts/AdminContext';

export default function Admin() {
  const { t } = useTranslation();

  const [adminData, setAdminData] = useContext(AdminContext);

  const [goals, setGoals] = useState([]);
  const [adminConflictsAndSynergies, setAdminConflictsAndSynergies] = useState([]);

  useEffect(() => {
    async function fetchGoals() {
      if (localStorage.getItem('user')) {
        const goalsFromAPI = await GoalService.getAllGoals();
        const adminConflictsAndSynergiesFromAPI = await GoalService.getAdminConflictsAndSynergies();
        setAdminConflictsAndSynergies(adminConflictsAndSynergiesFromAPI);
        setGoals(goalsFromAPI);
      } else {
        setGoals([]);
        setAdminConflictsAndSynergies([]);
      }
    }
    async function fetchAdminData() {
      const ad = await GoalService.getAdminData();
      setAdminData(ad);
    }
    fetchGoals();
    fetchAdminData();
  }, []);

  return (
    <>
      <Container className="white py-3 mt-lg-5 mb-5">
        <Row>
          <Col>
            <h1 className="h2">
              <i className="fal fa-cog mr-2" />
              {t('adminSettings')}
            </h1>
          </Col>
        </Row>

        {/* Admin goals start */}
        <Row className="pb-1">
          <Col>
            <Container>
              <fieldset className="pt-4 pb-2">
                <h4>{t('adminGoalHeader')}</h4>
                <p>{t('adminGoalLead')}</p>
                <div className="global-goal">
                  <Row>
                    <Col xs={12} md={6}>
                      <Goal
                        number={1}
                        goals={goals}
                        conflictsAndSynergies={adminConflictsAndSynergies}
                      />
                      <Goal
                        number={2}
                        goals={goals}
                        conflictsAndSynergies={adminConflictsAndSynergies}
                      />
                      <Goal
                        number={3}
                        goals={goals}
                        conflictsAndSynergies={adminConflictsAndSynergies}
                      />
                    </Col>
                    <Col xs={12} md={6}>
                      <Goal
                        number={4}
                        goals={goals}
                        conflictsAndSynergies={adminConflictsAndSynergies}
                      />
                      <Goal
                        number={5}
                        goals={goals}
                        conflictsAndSynergies={adminConflictsAndSynergies}
                      />
                      <Goal
                        number={6}
                        goals={goals}
                        conflictsAndSynergies={adminConflictsAndSynergies}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <Goal
                        number={7}
                        goals={goals}
                        conflictsAndSynergies={adminConflictsAndSynergies}
                      />
                      <Goal
                        number={8}
                        goals={goals}
                        conflictsAndSynergies={adminConflictsAndSynergies}
                      />
                      <Goal
                        number={9}
                        goals={goals}
                        conflictsAndSynergies={adminConflictsAndSynergies}
                      />
                    </Col>
                    <Col xs={12} md={6}>
                      <Goal
                        number={10}
                        goals={goals}
                        conflictsAndSynergies={adminConflictsAndSynergies}
                      />
                      <Goal
                        number={11}
                        goals={goals}
                        conflictsAndSynergies={adminConflictsAndSynergies}
                      />
                      <Goal
                        number={12}
                        goals={goals}
                        conflictsAndSynergies={adminConflictsAndSynergies}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <Goal
                        number={13}
                        goals={goals}
                        conflictsAndSynergies={adminConflictsAndSynergies}
                      />
                      <Goal
                        number={14}
                        goals={goals}
                        conflictsAndSynergies={adminConflictsAndSynergies}
                      />
                      <Goal
                        number={15}
                        goals={goals}
                        conflictsAndSynergies={adminConflictsAndSynergies}
                      />
                    </Col>
                    <Col xs={12} md={6}>
                      <Goal
                        number={16}
                        goals={goals}
                        conflictsAndSynergies={adminConflictsAndSynergies}
                      />
                      <Goal
                        number={17}
                        goals={goals}
                        conflictsAndSynergies={adminConflictsAndSynergies}
                      />
                      <span>
                        <img src="/img/17-global.svg" className="global" alt="" />
                      </span>
                    </Col>
                  </Row>
                </div>
              </fieldset>
            </Container>
          </Col>
        </Row>
      </Container>
    </>
  );
}
