import React, { useContext } from 'react';
import { Accordion } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import AccordionContext from 'react-bootstrap/AccordionContext';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import { SunContext } from '../contexts/SunContext';
import GlobalGoalMappingUrls from '../constants/GlobalGoalMappingUrls';

function AccordionAwareToggle({ children, eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext);
  const expandedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey),
  );

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <button
      type="button"
      className="btn btn-text btn-collapse btn-block text-white text-left d-flex"
      aria-expanded={isCurrentEventKey ? 'true' : 'false'}
      onClick={expandedOnClick}
    >
      {children}
    </button>
  );
}

const SummaryGoalSynergies = ({ goal }) => {
  const [sun, setSun] = useContext(SunContext);
  const { t } = useTranslation();
  return (
    <div>
      {
        sun.conflictsAndSynergies.filter((c) => c.type === 2
          && c.from.mainGoalNumber === goal.mainGoalNumber
          && c.from.subGoalNumber === goal.subGoalNumber).length > 0
        && (
          <Accordion className="accordion-effects">
            <AccordionAwareToggle eventKey="11">
              <i className="far fa-heart fa-lg mr-2" />
              <span>{t('synergies')}</span>
            </AccordionAwareToggle>
            <div>
              <Accordion.Collapse eventKey="11">
                <>
                  {sun.conflictsAndSynergies.filter((c) => c.type === 2
                    && c.from.mainGoalNumber === goal.mainGoalNumber
                    && c.from.subGoalNumber === goal.subGoalNumber)
                    .map((syn) => (
                      <p className="font-size-sm" key={syn.id}>
                        <a
                          href={GlobalGoalMappingUrls[syn.to.mainGoalNumber]}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={`badge mr-2 goal-bgcolor-${syn.to.mainGoalNumber}`}
                        >
                          {syn.to.mainGoalNumber}
                          .
                          {syn.to.subGoalNumber}
                        </a>
                        <span>{syn.to.name}</span>
                        <br />
                        <span>{syn.motivation}</span>
                      </p>
                    ))}
                </>
              </Accordion.Collapse>
            </div>
          </Accordion>
        )
      }
    </div>
  );
};

const SummaryGoalConflicts = ({ goal }) => {
  const [sun, setSun] = useContext(SunContext);
  const { t } = useTranslation();

  return (
    <>
      {
        sun.conflictsAndSynergies.filter((c) => c.type === 1
          && c.from.mainGoalNumber === goal.mainGoalNumber
          && c.from.subGoalNumber === goal.subGoalNumber).length > 0
        && (
          <Accordion className="accordion-effects">
            <AccordionAwareToggle eventKey="11">
              <i className="fas fa-heart-broken fa-lg mr-2" />
              <span>{t('conflicts')}</span>
            </AccordionAwareToggle>
            <div>
              <Accordion.Collapse eventKey="11">
                <>
                  {sun.conflictsAndSynergies.filter((c) => c.type === 1
                    && c.from.mainGoalNumber === goal.mainGoalNumber
                    && c.from.subGoalNumber === goal.subGoalNumber)
                    .map((conf) => (
                      <p className="font-size-sm" key={conf.id}>
                        <a
                          href={GlobalGoalMappingUrls[conf.to.mainGoalNumber]}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={`badge mr-2 goal-bgcolor-${conf.to.mainGoalNumber}`}
                        >
                          {conf.to.mainGoalNumber}
                          .
                          {conf.to.subGoalNumber}
                        </a>
                        <span>{conf.to.name}</span>
                        <br />
                        {conf.motivation ? <span className="text-wrap">{conf.motivation}</span> : <></>}
                      </p>
                    ))}
                </>
              </Accordion.Collapse>
            </div>
          </Accordion>
        )
      }
    </>

  );
};

const SummaryGoal = ({ goal }) => {
  const [sun, setSun] = useContext(SunContext);

  return (
    <div>
      {sun.sunGoals.filter((c) => c.mainGoalNumber === goal.mainGoalNumber).sort(
        (a, b) => Number(a.subGoalNumber) - Number(b.subGoalNumber),
      ).map(
        (g, index) => (
          <>
            <div className="accordion-targets" key={index}>
              <div>
                <a href={GlobalGoalMappingUrls[g.mainGoalNumber]} target="_blank" rel="noopener noreferrer" className={`badge goal-bgcolor-${g.mainGoalNumber}`}>
                  {g.mainGoalNumber}
                  .
                  {g.subGoalNumber}
                </a>
              </div>
              <div>
                <h5 className="m-0">{g.name}</h5>
                <p className="font-size-sm">{g.motivation}</p>
              </div>
            </div>
            <SummaryGoalConflicts goal={g} />
            <SummaryGoalSynergies goal={g} />
          </>
        ),
      )}
    </div>
  );
};

export default SummaryGoal;
