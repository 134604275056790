import React, { useState, createContext } from 'react';
import PropTypes from 'prop-types';

export const SunContext = createContext();

export const SunProvider = ({ children }) => {
  const [sun, setSun] = useState();
  return (
    <SunContext.Provider value={[sun, setSun]}>
      {children}
    </SunContext.Provider>
  );
};

SunProvider.propTypes = {
  children: PropTypes.element.isRequired,
};
