import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import TextareaAutosize from 'react-autosize-textarea';
import { Form } from 'react-bootstrap';
import { AdminContext } from '../contexts/AdminContext';
import GlobalGoalMappingUrls from '../constants/GlobalGoalMappingUrls';

const MiniConflictSynergyAdmin = ({
  goalToRender, handleDelete, parentGoal, motivationFromUpperComponent,
}) => {
  const { t } = useTranslation();
  const [adminData, setAdminData] = useContext(AdminContext);
  const [motivation, setMotivation] = useState(motivationFromUpperComponent || '');

  const handleMotivationChange = (motivation) => {
    setMotivation(motivation);
    const updatedGoal = adminData.adminConflictsAndSynergies.filter(
      (c) => c.from.mainGoalNumber === parentGoal.mainGoalNumber
      && c.from.subGoalNumber === parentGoal.subGoalNumber
      && c.to.mainGoalNumber === goalToRender.mainGoalNumber
      && c.to.subGoalNumber === goalToRender.subGoalNumber,
    )[0];
    updatedGoal.motivation = motivation;
    setAdminData(adminData);
  };

  const handleDeleteBtn = () => {
    handleDelete(goalToRender);
  };

  return (
    <div>
      <fieldset className="mb-2 mb-md-3 pt-2 pb-0 px-3">
        <Form.Group>
          <label className="mr-3">
            <a href={GlobalGoalMappingUrls[goalToRender.mainGoalNumber]} target="_blank" rel="noopener noreferrer" className={`badge mr-2 goal-bgcolor-${goalToRender.mainGoalNumber}`}>
              {goalToRender.mainGoalNumber}
              .
              {goalToRender.subGoalNumber}

            </a>
            {goalToRender.name}
          </label>
          <TextareaAutosize rows={1} className="form-control" value={motivation} onChange={(e) => handleMotivationChange(e.target.value)} placeholder={t('goalMotiveReasoning')} />
        </Form.Group>
        <button type="button" className="close" aria-label={t('delete')} onClick={() => handleDeleteBtn()}><span>{t('delete')}</span></button>
      </fieldset>
    </div>
  );
};

export default MiniConflictSynergyAdmin;
