import React, { useEffect } from 'react';
import {
  BrowserRouter as Router, Route, useHistory, Switch,
} from 'react-router-dom';
import { isIE } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

import { CookiesProvider } from 'react-cookie';
import NavBar from './components/NavBar';
import Start from './views/Start';
import Admin from './views/Admin';
import About from './views/About';
import Project from './views/Project';
import DataPolicy from './views/DataPolicy';
import CookiePolicy from './views/CookiePolicy';
import ResetPasswordWithVerificationCodeView from './views/ResetPasswordWithVerificationCodeView';
import UserService from './services/UserService';
import 'react-app-polyfill/stable';

import './styles/sustainabilitysun.scss';

import { SunProvider } from './contexts/SunContext';
import { ProjectProvider } from './contexts/ProjectContext';
import { AdminProvider } from './contexts/AdminContext';

const App = () => {
  const history = useHistory();
  const { i18n } = useTranslation();

  const HOUR_MS = 60000;

  useEffect(() => {
    i18n.changeLanguage('en');
    const interval = setInterval(() => {
      const expiredToken = UserService.checkExpiredToken();
      if (expiredToken !== undefined) {
        if (expiredToken < 8 && expiredToken > 7.95) {
          alert('Your session is about to run out, save your work');
        }
        if (expiredToken > 8) {
          alert('your session has expired. Please login again');
          UserService.logout();
          history.push({
            pathname: '/',
          });
        }
      }
    }, HOUR_MS);

    return () => clearInterval(interval);
  }, []);

  if (isIE) {
    return (
      <div>This browser is not supported for Sustainability Sun. Please use the latest version.</div>
    );
  }

  return (
    <Router>
      <Route exact path="/reset-password" component={ResetPasswordWithVerificationCodeView} />
      <CookiesProvider>
        <AdminProvider>
          <SunProvider>
            <ProjectProvider>
              <NavBar />
              <Switch>
                <Route exact path="/" component={Start} />
                <Route exact path="/about" component={About} />
                {localStorage.getItem('user') && UserService.getCurrentUser().isAdmin
                  && <Route exact path="/admin" component={Admin} />}
                <Route exact path="/project" component={Project} />
                <Route exact path="/datapolicy" component={DataPolicy} />
                <Route exact path="/cookiepolicy" component={CookiePolicy} />
              </Switch>
            </ProjectProvider>
          </SunProvider>
        </AdminProvider>
      </CookiesProvider>
    </Router>

  );
};

export default App;
