export default {
  1: 'https://www.globalgoals.org/goals/1-no-poverty/',
  2: 'https://www.globalgoals.org/goals/2-zero-hunger/',
  3: 'https://www.globalgoals.org/goals/3-good-health-and-well-being/',
  4: 'https://www.globalgoals.org/goals/4-quality-education/',
  5: 'https://www.globalgoals.org/goals/5-gender-equality/',
  6: 'https://www.globalgoals.org/goals/6-clean-water-and-sanitation/',
  7: 'https://www.globalgoals.org/goals/7-affordable-and-clean-energy/',
  8: 'https://www.globalgoals.org/goals/8-decent-work-and-economic-growth/',
  9: 'https://www.globalgoals.org/goals/9-industry-innovation-and-infrastructure/',
  10: 'https://www.globalgoals.org/goals/10-reduced-inequalities/',
  11: 'https://www.globalgoals.org/goals/11-sustainable-cities-and-communities/',
  12: 'https://www.globalgoals.org/goals/12-responsible-consumption-and-production/',
  13: 'https://www.globalgoals.org/goals/13-climate-action/',
  14: 'https://www.globalgoals.org/goals/14-life-below-water/',
  15: 'https://www.globalgoals.org/goals/15-life-on-land/',
  16: 'https://www.globalgoals.org/goals/16-peace-justice-and-strong-institutions/',
  17: 'https://www.globalgoals.org/goals/17-partnerships-for-the-goals/',
};
