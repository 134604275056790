import React, { useState } from 'react';
import {
  Container, Row, Col, Button,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CookieModal from '../components/CookieModal';
import NavBarExport from '../components/NavBarExport';
import SignInModal from '../components/SignInModal';

export default function Start() {
  const { t } = useTranslation();

  const [signInOpen, setModalSignInOpen] = useState(false);
  function onSignInClick() {
    setModalSignInOpen(true);
  }

  return (
    <>
      <CookieModal />
      <div className="front-page">
        <div className="hero">
          <Container>
            <Row className="row justify-content-center">
              <Col xs="10" md="8">
                <div className="jumbo clearfix">
                  <h1>{t('appName')}</h1>
                  <img src="/img/17-global.svg" alt="" className="startglobal" />
                  <p className="lead mb-0 pb-sm-3">{t('appLead')}</p>
                  {!localStorage.getItem('user')
                    ? (
                      <div className="text-center">
                        <Button className="text-nowrap" onClick={onSignInClick}>
                          <i className="far fa-sign-in" />
                          <span>{t('signIn')}</span>
                        </Button>
                      </div>
                    )
                    : <></>}
                </div>
              </Col>
            </Row>
            <NavBarExport />
          </Container>
        </div>
      </div>

      {signInOpen
        && (
          <SignInModal
            open={signInOpen}
            onHide={() => setModalSignInOpen(false)}
          />
        )}
    </>
  );
}
