import axios from 'axios';
import UserService from './UserService';
import AuthService from './AuthService';

class ProjectService {
  constructor() {
    this.getInstance = AuthService.getInstance;
    this.postInstance = AuthService.postInstance;
    this.deleteInstance = AuthService.deleteInstance;
    this.putInstance = AuthService.putInstance;
  }

  async registerProject(projectName) {
    return this.postInstance.post(`${process.env.REACT_APP_API_URL}project`,
      { ProjectName: projectName },
      { headers: { crossDomain: true, 'Content-Type': 'application/json', Authorization: `Bearer  ${UserService.getCurrentUser().token}` } })
      .then((res) => res.data).catch((err) => []);
  }

  async deleteProject(projectId) {
    return this.deleteInstance.delete(`${process.env.REACT_APP_API_URL}project/${projectId}`,
      { headers: { 'Content-Typ': 'application/json', Authorization: `Bearer ${UserService.getCurrentUser().token}` } })
      .then((res) => res).catch((err) => err);
  }

  async editProject(projectId, projectName) {
    axios.defaults.withCredentials = true;
    return this.putInstance.put(`${process.env.REACT_APP_API_URL}project`,
      { Id: projectId, Name: projectName },
      { headers: { 'Content-Typ': 'application/json', Authorization: `Bearer ${UserService.getCurrentUser().token}` } })
      .then((res) => res).catch((err) => err);
  }

  async getUserProjects() {
    return this.getInstance.get(`${process.env.REACT_APP_API_URL}project`,
      { headers: { Authorization: `Bearer ${UserService.getCurrentUser().token}` } })
      .then((res) => res.data).catch((err) => []);
  }

  async getSunsForProject(projectId) {
    return this.getInstance.get(`${process.env.REACT_APP_API_URL}project/suns/${projectId}`,
      { headers: { Authorization: `Bearer ${UserService.getCurrentUser().token}` } })
      .then((res) => res.data).catch((err) => []);
  }
}

export default new ProjectService();
