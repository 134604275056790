import React, { useState, useContext } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import GlobalGoalModal from './GlobalGoalModal';

import { SunContext } from '../contexts/SunContext';

const SunGoal = ({
  number, goals, saveGoals, addSynergy, onHide, isActive,
}) => {
  const { t } = useTranslation();
  const [sun, setSun] = useContext(SunContext);
  const [projectModal, setProjectModalOpen] = useState(false);
  const onHiding = () => {
    onHide();
    setProjectModalOpen(false);
  };

  return (
    <>
      <Button active={isActive} variant="text" id={`btn-goal-${number}`} onClick={(() => setProjectModalOpen(true))}>
        <img src={`/img/global-goal/${number}_${t('lang')}.svg`} alt="" />
      </Button>
      {projectModal
                && (
                <GlobalGoalModal
                  open={projectModal}
                  saveGoals={saveGoals}
                  onHide={onHiding}
                  data={goals}
                  number={number}
                  sun={sun}
                  addSynergy={addSynergy}
                />
                )}
    </>
  );
};

export default SunGoal;
