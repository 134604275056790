import React, { useState } from 'react';
import {
  Container, Row, Col, Button, Toast, Form,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import UserService from '../services/UserService';

function ResetPasswordWithVerificationCodeView() {
  const { t } = useTranslation();
  const [userForm, setUserForm] = useState({
    Email: '', VerificationCode: '', NewPassword: '',
  });
  const [showToast, setToastStatus] = useState(false);
  const resetPassword = async (e) => {
    e.preventDefault();
    const res = await UserService.passwordReset(userForm.Email, userForm.NewPassword, userForm.VerificationCode);
    if (res) {
      setToastStatus(true);
    } else {
      alert('Wrong verfification code or email');
    }
  };
  return (
    <>
      <Container className="white py-3 mt-lg-5 mb-5">
        <Row className="justify-content-center">
          <Toast show={showToast}>
            <Toast.Header>
              <img src="holder.js/20x20?text=%20" className="rounded mr-2" alt="" />
              <strong className="mr-auto">Password reset successfully!</strong>
              <small>{Date.now()}</small>
            </Toast.Header>
            <Toast.Body>
              Succesfully changed password! Please
              {' '}
              <b><a href="/">Sign In</a></b>
            </Toast.Body>
          </Toast>
        </Row>
        <Row>
          <Col>
            <h1 className="h2">Reset password with your verification code</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form id="formPasswordReset">
              <fieldset className="p-3 mb-0">
                <Form.Group controlId="formSignUpEmail">
                  <Form.Label>{t('email')}</Form.Label>
                  <Form.Control type="email" placeholder={t('email')} onChange={(e) => setUserForm({ ...userForm, Email: e.target.value })} />
                  <Form.Text className="text-muted">
                    {t('emailReset')}
                    {' '}
                  </Form.Text>
                </Form.Group>
                <Form.Group controlId="formSignUpVerificationCode">
                  <Form.Label>{t('verificationCode')}</Form.Label>
                  <Form.Control type="text" placeholder={t('verificationCode')} onChange={(e) => setUserForm({ ...userForm, VerificationCode: e.target.value })} />
                  <Form.Text className="text-muted">
                    {t('verificationCodeText')}
                    {' '}
                  </Form.Text>
                </Form.Group>
                <Form.Group controlId="formSignUpPassword">
                  <Form.Label>{t('password')}</Form.Label>
                  <Form.Control type="password" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" required placeholder={t('passwordEnterNew')} onChange={(e) => setUserForm({ ...userForm, NewPassword: e.target.value })} />
                  <Form.Text className="text-muted">
                    {t('passwordHelp')}
                  </Form.Text>
                </Form.Group>
              </fieldset>
              <Button className="float-right" variant="primary" type="submit" onClick={resetPassword}>
                <i className="far fa-unlock" />
                {t('passwordReset')}
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ResetPasswordWithVerificationCodeView;
