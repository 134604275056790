import axios from 'axios';
import Cookies from 'js-cookie';

class AuthService {
  constructor() {
    this.getInstance = axios.create({
      withCredentials: true,
    });
    this.getInstance.interceptors.request.use(
      (config) => config,
      (error) => {
        Promise.reject(error);
      },
    );
    this.getInstance.interceptors.response.use((res) => res, async (error) => {
      const originalRequest = error.config;
      if (error.response.status === 401 && originalRequest.url === `${process.env.REACT_APP_API_URL}users/refresh-token`) {
        return Promise.reject(error);
      }
      if (error.response.status === 401 && !originalRequest.retry) {
        originalRequest.retry = true;
        const refToken = Cookies.get('refreshToken');
        return this.getInstance.post(`${process.env.REACT_APP_API_URL}users/refresh-token`,
          { headers: { crossDomain: true, Authorization: `Bearer ${refToken}` } })
          .then((res) => {
            localStorage.setItem('user', JSON.stringify({
              token: res.data.jwtToken,
              name: res.data.username,
              isAdmin: res.data.isAdmin,
            }));
            return this.getInstance(originalRequest);
          });
      }
      return Promise.reject(error);
    });

    this.putInstance = axios.create({
      withCredentials: true,
    });
    this.putInstance.interceptors.request.use(
      (config) => config,
      (error) => {
        Promise.reject(error);
      },
    );
    this.putInstance.interceptors.response.use((res) => res, async (error) => {
      const originalRequest = error.config;
      if (error.response.status === 401 && originalRequest.url === `${process.env.REACT_APP_API_URL}users/refresh-token`) {
        return Promise.reject(error);
      }
      if (error.response.status === 401 && !originalRequest.retry) {
        originalRequest.retry = true;
        const refToken = Cookies.get('refreshToken');
        return this.putInstance.post(`${process.env.REACT_APP_API_URL}users/refresh-token`,
          { headers: { crossDomain: true, Authorization: `Bearer ${refToken}` } })
          .then((res) => {
            localStorage.setItem('user', JSON.stringify({
              token: res.data.jwtToken,
              name: res.data.username,
              isAdmin: res.data.isAdmin,
            }));
            return this.putInstance(originalRequest);
          });
      }
      return Promise.reject(error);
    });
    this.deleteInstance = axios.create({
      withCredentials: true,
    });
    this.deleteInstance.interceptors.request.use(
      (config) => config,
      (error) => {
        Promise.reject(error);
      },
    );
    this.deleteInstance.interceptors.response.use((res) => res, async (error) => {
      const originalRequest = error.config;
      if (error.response.status === 401 && originalRequest.url === `${process.env.REACT_APP_API_URL}users/refresh-token`) {
        return Promise.reject(error);
      }
      if (error.response.status === 401 && !originalRequest.retry) {
        originalRequest.retry = true;
        const refToken = Cookies.get('refreshToken');
        return this.deleteInstance.post(`${process.env.REACT_APP_API_URL}users/refresh-token`,
          { headers: { crossDomain: true, Authorization: `Bearer ${refToken}` } })
          .then((res) => {
            localStorage.setItem('user', JSON.stringify({
              token: res.data.jwtToken,
              name: res.data.username,
              isAdmin: res.data.isAdmin,
            }));
            return this.deleteInstance(originalRequest);
          });
      }
      return Promise.reject(error);
    });

    this.postInstance = axios.create({
      withCredentials: true,
    });
    this.postInstance.interceptors.request.use(
      (config) => config,
      (error) => {
        Promise.reject(error);
      },
    );
    this.postInstance.interceptors.response.use((res) => res, async (error) => {
      const originalRequest = error.config;
      if (error.response.status === 401 && originalRequest.url === `${process.env.REACT_APP_API_URL}users/refresh-token`) {
        return Promise.reject(error);
      }
      if (error.response.status === 401 && !originalRequest.retry) {
        originalRequest.retry = true;
        const refToken = Cookies.get('refreshToken');
        return this.postInstance.post(`${process.env.REACT_APP_API_URL}users/refresh-token`,
          { headers: { crossDomain: true, Authorization: `Bearer ${refToken}` } })
          .then((res) => {
            localStorage.setItem('user', JSON.stringify({
              token: res.data.jwtToken,
              name: res.data.username,
              isAdmin: res.data.isAdmin,
            }));
            return this.postInstance(originalRequest);
          });
      }
      return Promise.reject(error);
    });
  }
}

export default new AuthService();
