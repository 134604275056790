import React, { useState, useContext } from 'react';
import {
  Button, Row, Col, Modal, Form, InputGroup,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import ProjectMiniGoal from './ProjectMiniGoal';

import { SunContext } from '../contexts/SunContext';

function GlobalGoalModal({
  open, onHide, number, data, saveGoals, addSynergy,
}) {
  const { t } = useTranslation();

  const [sun, setSun] = useContext(SunContext);
  const [goal, setGoal] = useState(sun.goals.filter((c) => c.mainGoalNumber === number && c.subGoalNumber === '0')[0]);
  const [remainingGoalsThatCanBeAdded, setRemainingGoalsThatCanBeAdded] = useState(data.filter(
    (c) => c.mainGoalNumber === number,
  ));
  const [goalsToRender, setgoalsToRender] = useState(sun.sunGoals.filter(
    (goal) => goal.mainGoalNumber === number,
  ));
  const [selectedGoalToAdd, setSelectedGoalToAdd] = useState();

  const handleAddedGoal = () => {
    if (selectedGoalToAdd === undefined || selectedGoalToAdd === null) return;
    setSun({
      ...sun,
      sunGoals: sun.sunGoals.concat(sun.goals.filter(
        (c) => c.mainGoalNumber === selectedGoalToAdd.mainGoalNumber
          && c.subGoalNumber === selectedGoalToAdd.subGoalNumber,
      )),
    });
    const remainingGoalsThatCanBeAddedUpdated = remainingGoalsThatCanBeAdded.filter(
      (c) => c.subGoalNumber !== selectedGoalToAdd.subGoalNumber,
    );
    setRemainingGoalsThatCanBeAdded(remainingGoalsThatCanBeAddedUpdated);
  };

  const handleDeleteGoal = (goalToRemove) => {
    setSun({ ...sun, sunGoals: sun.sunGoals.filter((g) => g.id !== goalToRemove.id) });
  };

  const handleSelectedOptionToAdd = (e) => {
    const goalNumbers = e.target.value.split(' ')[0].split('.');
    const goalToadd = sun.goals.filter((c) => c.mainGoalNumber === Number(goalNumbers[0]) && c.subGoalNumber === goalNumbers[1]);
    setSelectedGoalToAdd(goalToadd[0]);
  };

  const saveGoalsToSun = async () => {
    saveGoals(goalsToRender, number, sun.id);
    onHide();
  };

  return (
    <>
      <Modal className="modal-page" show={open} onHide={onHide}>
        <Modal.Header className={`goal-bgcolor-${number}`}>
          <Modal.Title>
            {goal.mainGoalNumber}
            {' '}
            {goal.name}
          </Modal.Title>
          <Button variant="text" className="close" onClick={onHide} aria-label={t('close')}><span>{t('close')}</span></Button>
        </Modal.Header>
        <Modal.Body>

          <div className="add-target">
            <fieldset className="pt-2 pb-3 pt-lg-3 pb-lg-5 px-md-3 px-lg-5">
              <Row>
                <Col>
                  <Form.Label value={selectedGoalToAdd} className="mb-2 mb-lg-3">{t('goalLead')}</Form.Label>
                  <InputGroup>
                    <Form.Control as="select" id="addTargetSelect" onChange={handleSelectedOptionToAdd}>
                      <option>{t('goalTargetSelect')}</option>
                      {remainingGoalsThatCanBeAdded.map((g) => (
                        <option key={g.id}>
                          {g.mainGoalNumber}
                          .
                          {g.subGoalNumber}
                          {' '}
                          {g.name}
                        </option>
                      ))}
                    </Form.Control>
                    <InputGroup.Append>
                      <Button variant="primary" onClick={handleAddedGoal}>{t('add')}</Button>
                    </InputGroup.Append>
                  </InputGroup>
                </Col>
              </Row>
            </fieldset>
          </div>

          {sun.sunGoals.filter((c) => c.mainGoalNumber === number).sort(
            (a, b) => a.subGoalNumber - b.subGoalNumber,
          ).map((g) => (
            <ProjectMiniGoal
              key={g.id}
              allGoals={data}
              goalToRender={g}
              sunFromParent={sun}
              handleDeleteGoal={handleDeleteGoal}
              addSynergy={addSynergy}
            />
          ))}

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className="btn-lg" onClick={onHide}>{t('cancel')}</Button>
          <Button variant="primary" className="btn-lg" onClick={saveGoalsToSun}>{t('save')}</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default GlobalGoalModal;
