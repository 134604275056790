import React, {
  useState, useEffect, useContext, useRef,
} from 'react';
import {
  Container, Row, Col, ButtonGroup, Button,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Typeahead } from 'react-bootstrap-typeahead';

import NavBarExport from '../components/NavBarExport';
import SummaryAccordion from '../components/SummaryAccordion';
import ProjectModal from '../components/ProjectModal';
import SunModal from '../components/SunModal';
import GlobalGoalModal from '../components/GlobalGoalModal';
import DeleteModal from '../components/DeleteModal';

import ProjectService from '../services/ProjectService';
import SunService from '../services/SunService';
import GoalService from '../services/GoalService';
import SunGoal from '../components/SunGoal';

import { SunContext } from '../contexts/SunContext';
import { ProjectContext } from '../contexts/ProjectContext';
import Sun from '../components/Sun';

const Project = (props) => {
  const { t } = useTranslation();

  const [project, setProject] = useContext(ProjectContext);
  const [suns, setSuns] = useState([]);
  const [selectedSun, setselectedSun] = useContext(SunContext);
  const [goals, setGoals] = useState([]);
  const typeahead = useRef(null);

  const updateNewSun = (newSun) => {
    setSuns(suns.concat(newSun));
  };

  useEffect(() => {
    async function fetchSuns() {
      if (localStorage.getItem('user')) {
        const sunForProjectFromAPI = await ProjectService.getSunsForProject(
          props.location.state.id,
        );
        setSuns(sunForProjectFromAPI);
      } else {
        setSuns([]);
      }
    }
    async function fetchGoals() {
      if (localStorage.getItem('user')) {
        const goalsFromAPI = await GoalService.getAllGoals();
        setGoals(goalsFromAPI);
      } else {
        setGoals([]);
      }
    }
    fetchSuns(props.location.state.id);
    fetchGoals();
  }, [props, selectedSun]);

  const saveGoalsToSun = async () => {
    const res = await SunService.syncSunData(selectedSun);
    setselectedSun(res.data);
  };

  const handleSunChange = async (selected) => {
    if (selected[0]) {
      const res = await SunService.getSunInformation(selected[0].id);
      setselectedSun(res.data);
      typeahead.current.clear();
    } else {
      setselectedSun(null);
    }
  };

  const [modalGlobalGoalOpen, setModalGlobalGoalOpen] = useState(false);
  function onGlobalGoalClick() {
    setModalGlobalGoalOpen(true);
  }

  const [modalDeleteOpen, setModalDeleteOpen] = useState(false);

  const [modalProjectOpen, setModalProjectOpen] = useState(false);
  function onProjectClick() {
    setModalProjectOpen(true);
  }

  const [modalSunOpen, setModalSunOpen] = useState(false);
  function onSunAddClick() {
    setModalSunOpen(true);
  }

  const [modalSunEditOpen, setModalSunEditOpen] = useState(false);
  const onSunEditClick = () => {
    setModalSunEditOpen(true);
  };

  const [modalProjectEditOpen, setModalprojectEditOpen] = useState(false);
  const onProjectEditClick = () => {
    setModalprojectEditOpen(true);
  };

  const handleGlobalModalClose = async () => {
    const updatedSun = await SunService.getSunInformation(selectedSun.id);
    setselectedSun(updatedSun.data);
  };

  const shouldBeActive = (goalNumber) => selectedSun.sunGoals.filter(
    (g) => g.mainGoalNumber === goalNumber,
  ).length > 0;

  if (project === undefined || project === null) {
    return (<div />);
  }

  return (
    <>
      <Container className="white mt-lg-5 mb-md-5">
        <Row className="py-3">
          <Col>
            <h1 className="h2 mb-0">{project.name}</h1>
          </Col>
          <Col className="d-flex justify-content-end align-items-start">
            <Button variant="primary" onClick={onProjectEditClick}>{t('edit')}</Button>
          </Col>
        </Row>

        <Row className="pb-lg-3">
          <Col>
            <fieldset>
              <Row>
                <Col className="col-12 col-lg-6">
                  <Typeahead
                    id="selectSun"
                    labelKey={(sun) => `${sun.name}`}
                    options={suns}
                    ref={typeahead}
                    placeholder={t('sunSelect')}
                    clearButton
                    onChange={handleSunChange}
                  />
                </Col>
                <Col className="col-12 col-lg-6 mt-3 mt-lg-0 d-flex justify-content-end align-items-start">
                  <ButtonGroup>
                    <Button variant="primary" onClick={onSunAddClick}>
                      <i className="icon-sun-white" />
                      {t('sunAdd')}
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
            </fieldset>
          </Col>
        </Row>
      </Container>

      {selectedSun
        ? (
          <Container className="white pt-3 mb-8 mb-lg-12">
            <Row className="">
              <Col xs={8}>
                {selectedSun.name
                  ? <h2>{selectedSun.name}</h2>
                  : <></>}
              </Col>
              <Col xs={4} className="d-flex justify-content-end align-items-start">
                <Button variant="primary" onClick={onSunEditClick}>
                  <i className="icon-sun-white" />
                  {t('edit')}
                </Button>
              </Col>
            </Row>
            <Row className="pb-3">
              <Col xs={12} md={6} className="global-sun" id="global-sun">
                <Sun sun={selectedSun} />
              </Col>
              <Col xs={12} md={6}>
                <h4>Overall Sustainability Assessment</h4>
                {selectedSun.description
                  ? <p>{selectedSun.description}</p>
                  : <></>}
                <SummaryAccordion sunToRender={selectedSun} />

              </Col>
            </Row>
            <Row className="pb-3">
              <Col>
                <fieldset className="pt-4 pb-2">
                  <h4>{t('goalHeader')}</h4>
                  <p>{t('goalLead')}</p>
                  <div className="global-goal">
                    <Row>
                      <Col xs={12} md={6}>
                        <SunGoal
                          number={1}
                          goals={goals}
                          sun={selectedSun}
                          saveGoals={saveGoalsToSun}
                          onHide={handleGlobalModalClose}
                          isActive={shouldBeActive(1)}
                        />
                        <SunGoal
                          number={2}
                          goals={goals}
                          sun={selectedSun}
                          saveGoals={saveGoalsToSun}
                          onHide={handleGlobalModalClose}
                          isActive={shouldBeActive(2)}
                        />
                        <SunGoal
                          number={3}
                          goals={goals}
                          sun={selectedSun}
                          saveGoals={saveGoalsToSun}
                          onHide={handleGlobalModalClose}
                          isActive={shouldBeActive(3)}
                        />
                      </Col>
                      <Col xs={12} md={6}>
                        <SunGoal
                          number={4}
                          goals={goals}
                          sun={selectedSun}
                          saveGoals={saveGoalsToSun}
                          onHide={handleGlobalModalClose}
                          isActive={shouldBeActive(4)}
                        />
                        <SunGoal
                          number={5}
                          goals={goals}
                          sun={selectedSun}
                          saveGoals={saveGoalsToSun}
                          onHide={handleGlobalModalClose}
                          isActive={shouldBeActive(5)}
                        />
                        <SunGoal
                          number={6}
                          goals={goals}
                          sun={selectedSun}
                          saveGoals={saveGoalsToSun}
                          onHide={handleGlobalModalClose}
                          isActive={shouldBeActive(6)}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={6}>
                        <SunGoal
                          number={7}
                          goals={goals}
                          sun={selectedSun}
                          saveGoals={saveGoalsToSun}
                          onHide={handleGlobalModalClose}
                          isActive={shouldBeActive(7)}
                        />
                        <SunGoal
                          number={8}
                          goals={goals}
                          sun={selectedSun}
                          saveGoals={saveGoalsToSun}
                          onHide={handleGlobalModalClose}
                          isActive={shouldBeActive(8)}
                        />
                        <span>
                            <img src="/img/17-global.svg" className="global" alt="" />
                        </span>
                      </Col>
                    </Row>
                  </div>
                </fieldset>
              </Col>
            </Row>
          </Container>
        )
        : <></>}

      {
        modalGlobalGoalOpen
        && (
          <GlobalGoalModal
            open={modalGlobalGoalOpen}
            saveGoals={saveGoalsToSun}
            onHide={() => setModalGlobalGoalOpen(false)}
          />
        )
      }

      {
        modalProjectOpen
        && <ProjectModal open={modalProjectOpen} onHide={() => setModalProjectOpen(false)} />
      }

      {
        modalProjectEditOpen
        && (
          <ProjectModal
            open={modalProjectEditOpen}
            onHide={() => setModalprojectEditOpen(false)}
            isEdit
            name={project.name}
            id={project.id}
            setProject={setProject}
          />
        )
      }

      {
        modalSunOpen
        && (
          <SunModal
            open={modalSunOpen}
            onHide={() => setModalSunOpen(false)}
            projectId={project.id}
            newSun={updateNewSun}
          />
        )
      }

      {
        modalDeleteOpen
        && (
          <DeleteModal
            open={modalDeleteOpen}
            onHide={() => setModalDeleteOpen(false)}
          />
        )
      }

      {modalSunEditOpen
        && (
          <SunModal
            open={modalSunEditOpen}
            onHide={() => setModalSunEditOpen(false)}
            projectId={project.id}
            isEdit
            setSuns={setSuns}
          />
        )}

      <NavBarExport />
    </>
  );
};

export default Project;
