/* eslint-disable no-restricted-syntax */
import React, { useEffect } from 'react';
import './Sun.css';

const Sun = ({ sun }) => {
  const resetSun = () => {
    const root = document.querySelectorAll('[id^="sun"]');
    root.forEach(path => path.classList.remove("active"))
    const rootNegative = document.querySelectorAll('[id^="n"]');
    rootNegative.forEach(path =>  path.classList.remove("active"));
  };

  const lightUp = (upToNumber, sunBeamNumber) => {
    for (let i = 1; i <= upToNumber; i += 1) {
      document.getElementById(`sun_${sunBeamNumber}_${i}`).classList.add('active');
    }
  };
  const lightUpNegative = (upToNumber, sunBeamNumber) => {
    for (let i = 1; i <= upToNumber; i += 1) {
      document.getElementById(`n${sunBeamNumber}_${i}`).classList.add('active');
    }
  };

  const calculateValuesInSun = () => {
    const values = new Map();
    const goalNumbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17];
    goalNumbers.map((g) => {
      const goalsMatching = sun.sunGoals?.filter((c) => c.mainGoalNumber === g);
      const nonNegativeValuesCount = goalsMatching.filter((c) => c.valuation >= 0).length;
      const nonNegativeValuesSum = goalsMatching.filter((c) => c.valuation >= 0).map((c) => c.valuation).reduce((a, b) => a + b, 0);
      const maxNegativeValue = Math.min(...goalsMatching.filter((c) => c.valuation < 0).map((c) => c.valuation));
      const shouldRender = sun.sunGoals?.filter((c) => c.mainGoalNumber === g).length > 0;
      values.set(g, ({ maxNegative: maxNegativeValue, numberOfBeamsToLightUp: nonNegativeValuesSum / nonNegativeValuesCount, shouldRender }));
    });
    return values;
  };

  const setActive = (values) => {
    for (const [key, value] of values) {
      if (!value) { return; }
      if (value.shouldRender) {
        document.getElementById(`sun-logo-${key}`).classList.add('active');
        if (value.maxNegative !== Infinity) {
          lightUpNegative(Math.abs(value.maxNegative), key);
        }
        else{
          const averageScoreOfPositiveValuations = value.numberOfBeamsToLightUp;
        if (averageScoreOfPositiveValuations >= 2.75) {
          lightUp(6, key);
        }
        if (averageScoreOfPositiveValuations < 2.75 && averageScoreOfPositiveValuations >= 2.5) {
          lightUp(5, key);
        }
        if (averageScoreOfPositiveValuations < 2.5 && averageScoreOfPositiveValuations >= 2.0) {
          lightUp(4, key);
        }
        if (averageScoreOfPositiveValuations < 2.0 && averageScoreOfPositiveValuations >= 1.5) {
          lightUp(3, key);
        }
        if (averageScoreOfPositiveValuations < 1.5 && averageScoreOfPositiveValuations >= 1.0) {
          lightUp(2, key);
        }
        if (averageScoreOfPositiveValuations < 1.0 && averageScoreOfPositiveValuations >= 0.5) {
          lightUp(1, key);
        }
        }
      }
    }
  };

  useEffect(() => {
    resetSun();
    setActive(calculateValuesInSun());
  }, [sun]);

  return (
    <svg id="sun" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 850 850">
      <g id="sun">
        <g id="sun-logo">
          <path id="sun-logo-17" d="M419.57,250v75.1a99.87,99.87,0,0,0-25.4,4.8l-27.1-70A174.22,174.22,0,0,1,419.57,250Z" transform="translate(0)" />
          <path id="sun-logo-16" d="M356.77,263.81l27.1,70a99.74,99.74,0,0,0-22,13.6L311.27,292A178.25,178.25,0,0,1,356.77,263.81Z" transform="translate(0)" />
          <path id="sun-logo-15" d="M303.17,299.41l50.6,55.5a101.29,101.29,0,0,0-15.6,20.7L271,342.11A174.18,174.18,0,0,1,303.17,299.41Z" transform="translate(0)" />
          <path id="sun-logo-14" d="M266,351.91l67.2,33.5a97.27,97.27,0,0,0-7.1,24.9l-74.7-6.9A174.35,174.35,0,0,1,266,351.91Z" transform="translate(0)" />
          <path id="sun-logo-13" d="M250.37,414.31l74.8,6.9a101.68,101.68,0,0,0,2.4,25.8l-72.2,20.5A173.31,173.31,0,0,1,250.37,414.31Z" transform="translate(0)" />
          <path id="sun-logo-12" d="M258.27,478.21l72.2-20.6a100.71,100.71,0,0,0,11.6,23.2L282.17,526A174.1,174.1,0,0,1,258.27,478.21Z" transform="translate(0)" />
          <path id="sun-logo-11" d="M288.77,534.81l59.9-45.3a99,99,0,0,0,19.1,17.4l-39.5,63.8A168.76,168.76,0,0,1,288.77,534.81Z" transform="translate(0)" />
          <path id="sun-logo-10" d="M337.67,576.61l39.5-63.8a99.25,99.25,0,0,0,24.1,9.3l-13.8,73.8A171.78,171.78,0,0,1,337.67,576.61Z" transform="translate(0)" />
          <path id="sun-logo-9" d="M398.37,598l13.8-73.8a102.05,102.05,0,0,0,25.9,0l13.8,73.8A171.42,171.42,0,0,1,398.37,598Z" transform="translate(0)" />
          <path id="sun-logo-8" d="M462.67,596l-13.8-73.8a95.77,95.77,0,0,0,24.1-9.4l39.5,63.8A175,175,0,0,1,462.67,596Z" transform="translate(0)" />
          <path id="sun-logo-7" d="M521.87,570.91,482.27,507a102.35,102.35,0,0,0,19.1-17.5l59.9,45.2A173.89,173.89,0,0,1,521.87,570.91Z" transform="translate(0)" />
          <path id="sun-logo-6" d="M568,526l-59.9-45.2a96.36,96.36,0,0,0,11.5-23.2l72.2,20.5A168.83,168.83,0,0,1,568,526Z" transform="translate(0)" />
          <path id="sun-logo-5" d="M594.87,467.61,522.67,447a101.68,101.68,0,0,0,2.4-25.8l74.7-6.9A174,174,0,0,1,594.87,467.61Z" transform="translate(0)" />
          <path id="sun-logo-4" d="M598.77,403.41l-74.8,6.9a97.27,97.27,0,0,0-7.1-24.9l67.2-33.4A173.82,173.82,0,0,1,598.77,403.41Z" transform="translate(0)" />
          <path id="sun-logo-3" d="M579.27,342.11l-67.2,33.5a100.67,100.67,0,0,0-15.6-20.6l50.6-55.5A176.45,176.45,0,0,1,579.27,342.11Z" transform="translate(0)" />
          <path id="sun-logo-2" d="M538.87,292l-50.6,55.5a96.32,96.32,0,0,0-22-13.6l27.1-70A171,171,0,0,1,538.87,292Z" transform="translate(0)" />
          <path id="sun-logo-1" d="M483.17,259.91l-27.1,70a96.29,96.29,0,0,0-25.5-4.7v-75.1A171.89,171.89,0,0,1,483.17,259.91Z" transform="translate(0)" />
        </g>
        <g id="sun-numbers">
          <g id="sun-number17">
            <polygon points="397.57 264.51 408.67 264.51 408.67 268.61 402.87 290.91 398.47 290.91 404.47 268.11 397.57 268.11 397.57 264.51" />
            <polygon points="395.57 264.51 395.57 290.91 391.17 290.91 391.17 270.71 391.07 270.71 387.97 273.91 387.97 273.91 387.97 269.31 392.07 264.51 395.57 264.51" />
          </g>
          <g id="sun-number16">
            <path d="M356.47,304.41v-3.9c0-4.3-1.7-6.5-4.9-6.5a3.86,3.86,0,0,0-3.7,2.1h0v-5.7c0-1.6.5-2.7,2.1-2.7s2.1.9,2.1,2.6v1.8h4v-1.4c0-3.9-1.3-6.6-6.2-6.6-4.7,0-6.4,3-6.4,7.1V304c0,4.1,1.8,7,6.5,7s6.5-2.5,6.5-6.6m-4.4.4c0,1.7-.6,2.6-2.1,2.6s-2.1-1-2.1-2.6V300c.1-1.5.7-2.4,2.1-2.4s2.1.9,2.1,2.6Z" transform="translate(0)" />
            <polygon points="333.37 289.21 333.37 293.81 333.37 293.81 336.47 290.61 336.47 290.61 336.47 310.71 340.97 310.71 340.97 284.41 337.37 284.41 333.37 289.21" />
          </g>
          <g id="sun-number15">
            <path d="M311.17,328.81a4.09,4.09,0,0,0-3.7,1.8h0V324h7.6v-3.6h-11.7v14.9h4v-.6c0-1.6.7-2.4,1.9-2.4,1.4,0,1.9.9,1.9,2.4v6.4c0,1.4-.5,2.4-1.9,2.4s-2.1-1-2.1-2.6v-3.3h-4v3c0,4,1.6,6.6,6.1,6.6s6.3-2.4,6.3-6v-6.5c.1-4.2-2-5.9-4.4-5.9" transform="translate(0)" />
            <polygon points="292.97 325.21 292.97 329.91 293.07 329.91 296.17 326.61 296.17 326.61 296.17 346.81 300.67 346.81 300.67 320.41 297.07 320.41 292.97 325.21" />
          </g>
          <g id="sun-number14">
            <path d="M290.57,398v-4.3h1.8v-3.5h-1.8v-18.6h-4.4l-7.7,19.4v2.7h7.8V398Zm-8.1-7.9,4-10.8h0v10.8Z" transform="translate(0)" />
            <polygon points="277.07 371.51 277.07 398.01 272.57 398.01 272.57 377.71 272.57 377.71 269.47 381.01 269.47 381.01 269.47 376.41 273.47 371.51 277.07 371.51" />
          </g>
          <g id="sun-number13">
            <path d="M287.17,430.91v2.3a4.21,4.21,0,0,1-3,4.3c2.2.5,3.3,2.1,3.3,4.5v3.9c0,3.7-1.9,6-6.4,6s-6.1-2.6-6.1-6.6v-3.5h4v3.8c0,1.6.5,2.6,2.1,2.6,1.4,0,2-1,2-2.4v-4.4c0-1.4-.6-2.1-2.2-2.1H279v-3.4h1.7c1.4,0,2.4-.7,2.4-2.1V431c0-1.4-.5-2.4-1.9-2.4s-1.9.9-1.9,2.6v2.7h-4v-2.3c0-3.9,1.6-6.6,6.2-6.6,3.9-.1,5.7,2.2,5.7,5.9" transform="translate(0)" />
            <polygon points="272.57 425.21 272.57 451.51 268.17 451.51 268.17 431.31 268.07 431.31 264.97 434.61 264.97 434.61 264.97 429.91 268.97 425.21 272.57 425.21" />
          </g>
          <g id="sun-number12">
            <path d="M289.17,482.81V486h4v-3.3c0-1.6.8-2.1,1.7-2.1s1.6.3,1.6,2v2.2c0,2.2-.2,3-1.6,4.5l-2.5,2.6c-2.4,2.6-3.3,4.3-3.3,6.9v4.9h11.5v-3.6h-7.3v-1.2c0-2.1.7-2.8,1.9-4l2.8-3c2.3-2.4,2.8-4,2.8-6.6v-2.6c0-3.8-1.8-5.6-5.8-5.6-3.8,0-5.8,2-5.8,5.7" transform="translate(0)" />
            <polygon points="282.07 483.61 282.07 483.61 282.07 503.81 286.57 503.81 286.57 477.41 282.97 477.41 278.87 482.31 278.87 486.81 278.97 486.81 282.07 483.61" />
          </g>
          <g id="sun-number11">
            <polygon points="315.57 526.91 315.57 531.51 315.57 531.51 318.57 528.31 318.77 528.31 318.77 548.51 323.07 548.51 323.07 522.11 319.57 522.11 315.57 526.91" />
            <polygon points="325.57 526.91 325.57 531.51 325.57 531.51 328.87 528.31 328.87 528.31 328.87 548.51 333.27 548.51 333.27 522.11 329.77 522.11 325.57 526.91" />
          </g>
          <g id="sun-number10">
            <path d="M384.17,570.51v-12.8c0-4.1-2.1-7.1-6.6-7.1s-6.5,3-6.5,7.1v12.8c0,4.1,2.1,7.1,6.5,7.1s6.6-2.9,6.6-7.1m-8.7.8v-14.2c0-1.6.6-2.7,2.1-2.7s2.1,1.1,2.1,2.7v14.2c0,1.6-.6,2.7-2.1,2.7s-2.1-1.1-2.1-2.7" transform="translate(0)" />
            <polygon points="363.57 557.21 363.57 577.41 367.97 577.41 367.97 551.01 364.47 551.01 360.37 555.81 360.37 560.41 360.37 560.41 363.47 557.21 363.57 557.21" />
          </g>
          <path id="sun-number9" d="M431.57,568.71c0-4.1-1.8-7-6.5-7s-6.5,2.6-6.5,6.6v3.9c0,4.3,1.7,6.5,4.9,6.5a3.86,3.86,0,0,0,3.7-2.1h0v5.7c0,1.6-.6,2.7-2.2,2.7s-2.2-.9-2.2-2.6v-1.7h-4v1.4c0,3.9,1.4,6.6,6.3,6.6,4.7,0,6.5-3,6.5-7.1v-12.9Zm-4.4,4.1c-.1,1.5-.7,2.4-2.1,2.4s-2.1-.9-2.1-2.6V568c0-1.7.6-2.6,2.1-2.6s2.1,1,2.1,2.6Z" transform="translate(0)" />
          <path id="sun-number8" d="M485.47,571v-2.9c0-2.5-.9-4.4-3-5.3a4.91,4.91,0,0,0,2.6-4.6v-1.1c0-4.1-2.1-6.3-6.2-6.3s-6.1,2.2-6.1,6.3v1.1a4.82,4.82,0,0,0,2.5,4.6c-2.1.9-2.9,2.8-2.9,5.3V571c0,4,1.9,6.8,6.6,6.8s6.5-2.8,6.5-6.8m-8.6-14.1c0-1.6.5-2.5,2-2.5s2,.9,2,2.5v1.8c0,1.6-.5,2.5-2,2.5s-2-.9-2-2.5Zm4.2,14.6c0,1.6-.6,2.7-2.2,2.7s-2.1-1.1-2.1-2.7v-4.3c0-1.6.6-2.7,2.1-2.7s2.2,1.1,2.2,2.7Z" transform="translate(0)" />
          <polygon id="sun-number7" points="522.07 527.71 528.87 527.71 522.87 550.51 527.37 550.51 533.07 528.21 533.07 524.11 522.07 524.11 522.07 527.71" />
          <path id="sun-number6" d="M560.87,504.81c4.7,0,6.5-2.6,6.5-6.7v-3.9c0-4.3-1.7-6.5-4.9-6.5a3.86,3.86,0,0,0-3.7,2.1h0V484c0-1.6.5-2.7,2.1-2.7s2.1.9,2.1,2.6v1.7h4v-1.4c0-3.9-1.3-6.6-6.2-6.6-4.7,0-6.4,3-6.4,7.1v12.8c0,4.3,1.8,7.3,6.5,7.3m-2.1-11.1c.1-1.5.7-2.4,2.1-2.4s2.1.9,2.1,2.6v4.6c0,1.7-.6,2.6-2.1,2.6s-2.1-1-2.1-2.6Z" transform="translate(0)" />
          <path id="sun-number5" d="M576.77,433.41a4.09,4.09,0,0,0-3.7,1.8h0v-6.6h7.6V425H569v14.9h4v-.6c0-1.6.7-2.4,1.9-2.4,1.4,0,1.9.9,1.9,2.4v6.4c0,1.4-.5,2.4-1.9,2.4s-2.1-1-2.1-2.6v-3.3h-4v3c0,4,1.6,6.6,6.1,6.6s6.3-2.4,6.3-6v-6.5c.1-4.2-2.1-5.9-4.4-5.9" transform="translate(0)" />
          <path id="sun-number4" d="M571.27,395.91h4.2v-4.3h1.8v-3.5h-1.8v-18.5h-4.4l-7.7,19.4v2.7h7.8v4.2Zm-4-7.7,4-10.8h0v10.8Z" transform="translate(0)" />
          <path id="sun-number3" d="M545.57,346.51c4.5,0,6.4-2.4,6.4-6v-3.9c0-2.4-1-4-3.3-4.5a4.21,4.21,0,0,0,3-4.3v-2.3c0-3.7-1.8-6-5.9-6-4.6,0-6.2,2.7-6.2,6.6v2.3h4v-2.6c0-1.6.5-2.6,1.9-2.6s1.9.9,1.9,2.4v2.8c0,1.4-.9,2.1-2.4,2.1h-1.7v3.4h1.9c1.6,0,2.2.7,2.2,2.1v4.4c0,1.4-.6,2.4-2,2.4s-2.1-1-2.1-2.6v-3.8h-4v3.5c.1,4,1.8,6.6,6.3,6.6" transform="translate(0)" />
          <path id="sun-number2" d="M510,307.21h-7.3V306c0-2.1.7-2.8,1.9-4l2.8-3c2.3-2.4,2.8-4,2.8-6.6v-2.6c0-3.8-1.8-5.6-5.8-5.6s-5.9,2.1-5.9,5.8v3.2h4v-3.3c0-1.6.8-2.1,1.7-2.1s1.6.4,1.6,2V292c0,2.2-.2,3-1.6,4.5l-2.5,2.6c-2.4,2.6-3.3,4.3-3.3,6.9v4.9h11.5l.1-3.7Z" transform="translate(0)" />
          <polygon id="sun-number1" points="451.27 269.11 451.27 269.11 451.27 289.31 455.67 289.31 455.67 262.91 452.17 262.91 448.07 267.71 448.07 272.31 448.07 272.31 451.27 269.11" />
        </g>
        <g id="neg">
          <path id="n17_2" d="M406.63,362l12.8,33.25V359.71A61.07,61.07,0,0,0,406.63,362Z" transform="translate(0)" />
          <path id="n17_1" d="M419.57,360.21v-27.6a97.84,97.84,0,0,0-22.7,4.2l10,25.8A66.18,66.18,0,0,1,419.57,360.21Z" transform="translate(0)" />
          <path id="n16_2" d="M409.15,399.41l-12.9-33.35A64.81,64.81,0,0,0,385.07,373Z" transform="translate(0)" />
          <path id="n16_1" d="M396.47,366.61l-9.9-25.7a89,89,0,0,0-19.7,12.2l18.6,20.4A59.75,59.75,0,0,1,396.47,366.61Z" transform="translate(0)" />
          <path id="n15_2" d="M401,406.87l-24.09-26.4a74,74,0,0,0-8,10.48Z" transform="translate(0)" />
          <path id="n15_1" d="M377.37,380.91l-18.6-20.4a89.62,89.62,0,0,0-13.9,18.5l24.6,12.3A62,62,0,0,1,377.37,380.91Z" transform="translate(0)" />
          <path id="n14_2" d="M364.07,400.82a59.85,59.85,0,0,0-3.52,12.6l35.52,3.33Z" transform="translate(0)" />
          <path id="n14_1" d="M364.67,401.11,340,388.81a86.65,86.65,0,0,0-6.3,22.2l27.4,2.5A62.89,62.89,0,0,1,364.67,401.11Z" transform="translate(0)" />
          <path id="n13_2" d="M359.47,424.51v.5a65,65,0,0,0,1.21,12.6l34.39-9.78Z" transform="translate(0)" />
          <path id="n13_1" d="M360.07,425v-.5l-27.5-2.5a93.6,93.6,0,0,0,2.2,23l26.5-7.5A64.73,64.73,0,0,1,360.07,425Z" transform="translate(0)" />
          <path id="n12_2" d="M398,438.51l-34.26,9.78A66.43,66.43,0,0,0,369.55,460Z" transform="translate(0)" />
          <path id="n12_1" d="M364.27,448.11l-26.5,7.5a93,93,0,0,0,10.3,20.7l22-16.6A73.89,73.89,0,0,1,364.27,448.11Z" transform="translate(0)" />
          <path id="n11_2" d="M376.3,468.84a66.1,66.1,0,0,0,9.67,8.87l18.74-30.23Z" transform="translate(0)" />
          <path id="n11_1" d="M376.77,468.41l-22.1,16.7a97.38,97.38,0,0,0,17.1,15.6l14.5-23.5A65.44,65.44,0,0,1,376.77,468.41Z" transform="translate(0)" />
          <path id="n10_2" d="M395.34,483.56a71.12,71.12,0,0,0,12.2,4.73l6.53-35.07Z" transform="translate(0)" />
          <path id="n10_1" d="M395.67,483l-14.5,23.4a87.76,87.76,0,0,0,21.5,8.3l5.1-27.1A62.31,62.31,0,0,1,395.67,483Z" transform="translate(0)" />
          <path id="n9_2" d="M431.62,490.31l-6.55-35.07-6.55,35.07c2.12.2,4.33.3,6.55.3S429.5,490.51,431.62,490.31Z" transform="translate(0)" />
          <path id="n9_1" d="M425.07,490c-2.2,0-4.4-.1-6.5-.3l-5.1,27.1a90.08,90.08,0,0,0,23.2-.1l-5.1-27C429.47,489.91,427.27,490,425.07,490Z" transform="translate(0)" />
          <path id="n8_2" d="M436,453.22l6.55,35.07a64.06,64.06,0,0,0,12.2-4.73Z" transform="translate(0)" />
          <path id="n8_1" d="M442.37,487.71l5.1,27.1a92.32,92.32,0,0,0,21.5-8.4L454.47,483A70.82,70.82,0,0,1,442.37,487.71Z" transform="translate(0)" />
          <path id="n7_2" d="M464.27,477.71a66.1,66.1,0,0,0,9.67-8.87l-28.41-21.46Z" transform="translate(0)" />
          <path id="n7_1" d="M473.47,468.41a66.7,66.7,0,0,1-9.6,8.8l14.5,23.4a90.49,90.49,0,0,0,17.1-15.6Z" transform="translate(0)" />
          <path id="n6_2" d="M452.18,438.51,480.59,460a60,60,0,0,0,5.85-11.69Z" transform="translate(0)" />
          <path id="n6_1" d="M485.87,448.11a66.14,66.14,0,0,1-5.8,11.6l22,16.6a95.45,95.45,0,0,0,10.3-20.7Z" transform="translate(0)" />
          <path id="n5_2" d="M490.67,424.51l-35.6,3.32,34.36,9.78a64.24,64.24,0,0,0,1.24-12.6Z" transform="translate(0)" />
          <path id="n5_1" d="M517.47,422l-27.4,2.5v.5a64.73,64.73,0,0,1-1.2,12.5l26.5,7.5A88.88,88.88,0,0,0,517.47,422Z" transform="translate(0)" />
          <path id="n4_2" d="M454.19,416.75l35.47-3.33a74.57,74.57,0,0,0-3.52-12.6Z" transform="translate(0)" />
          <path id="n4_1" d="M489.07,413.51l27.5-2.5a95.81,95.81,0,0,0-6.4-22.2l-24.7,12.3A65.2,65.2,0,0,1,489.07,413.51Z" transform="translate(0)" />
          <path id="n3_2" d="M449.15,406.87,481.07,391a72.93,72.93,0,0,0-7.86-10.48Z" transform="translate(0)" />
          <path id="n3_1" d="M480.57,391.21l24.7-12.3a89.09,89.09,0,0,0-13.9-18.4l-18.6,20.4A61.05,61.05,0,0,1,480.57,391.21Z" transform="translate(0)" />
          <path id="n2_2" d="M441,399.31,465,373a68.62,68.62,0,0,0-11.18-6.95Z" transform="translate(0)" />
          <path id="n2_1" d="M464.67,373.41l18.6-20.4a102.53,102.53,0,0,0-19.7-12.2l-10,25.8A70.08,70.08,0,0,1,464.67,373.41Z" transform="translate(0)" />
          <path id="n1_2" d="M443.51,362a68.09,68.09,0,0,0-12.9-2.42v35.67Z" transform="translate(0)" />
          <path id="n1_1" d="M443.37,362.61l9.9-25.7a88.14,88.14,0,0,0-22.7-4.2v27.5A67.19,67.19,0,0,1,443.37,362.61Z" transform="translate(0)" />
        </g>
        <g id="sun_17">
          <path id="sun_17_1" d="M419.56,204.56a221.84,221.84,0,0,0-69.08,12.83l13.64,35.09A177.24,177.24,0,0,1,419.56,242Z" transform="translate(0)" />
          <path id="sun_17_2" d="M419.57,205.11v-37.6a258.1,258.1,0,0,0-82.4,15.4l13.5,35A218.6,218.6,0,0,1,419.57,205.11Z" transform="translate(0)" />
          <path id="sun_17_3" d="M419.56,122.22a300.22,300.22,0,0,0-98.83,18.42l13.62,35.15a263.21,263.21,0,0,1,85.21-16Z" transform="translate(0)" />
          <path id="sun_17_4" d="M419.57,122.61V85a338.56,338.56,0,0,0-112.2,21l13.5,34.9A302.07,302.07,0,0,1,419.57,122.61Z" transform="translate(0)" />
          <path id="sun_17_5" d="M419.56,39.51A383.58,383.58,0,0,0,290.9,63.44l13.61,35.05A345,345,0,0,1,419.56,76.86Z" transform="translate(0)" />
          <path id="sun_17_6" d="M419.57,40V2.41a420.59,420.59,0,0,0-142,26.6l13.5,35A385.78,385.78,0,0,1,419.57,40Z" transform="translate(0)" />
        </g>
        <g id="sun_16">
          <path id="sun_16_1" d="M306,286.16a180.67,180.67,0,0,1,47.92-29.67L340.26,221.4a220.53,220.53,0,0,0-59.65,37Z" transform="translate(0)" />
          <path id="sun_16_2" d="M340.47,221.91l-13.5-35a257.47,257.47,0,0,0-71.3,44.1l25.3,27.8A219.65,219.65,0,0,1,340.47,221.91Z" transform="translate(0)" />
          <path id="sun_16_3" d="M250.34,225.25a267.24,267.24,0,0,1,73.73-45.66l-13.52-35a304,304,0,0,0-85.41,53Z" transform="translate(0)" />
          <path id="sun_16_4" d="M310.67,144.91l-13.6-35a340.76,340.76,0,0,0-97,60.2l25.3,27.7A304.47,304.47,0,0,1,310.67,144.91Z" transform="translate(0)" />
          <path id="sun_16_5" d="M194.77,164.27a347.53,347.53,0,0,1,99.43-61.68L280.58,67.45a384.33,384.33,0,0,0-111.14,69Z" transform="translate(0)" />
          <path id="sun_16_6" d="M280.77,67.91,267.27,33a419.47,419.47,0,0,0-122.7,76.2l25.2,27.6A385.87,385.87,0,0,1,280.77,67.91Z" transform="translate(0)" />
        </g>
        <g id="sun_15">
          <path id="sun_15_1" d="M263.87,338.59a183.78,183.78,0,0,1,34-45l-25.38-27.78a219,219,0,0,0-42.31,56Z" transform="translate(0)" />
          <path id="sun_15_2" d="M272.87,266.21l-25.3-27.8a255.63,255.63,0,0,0-50.5,66.9l33.6,16.7A217.85,217.85,0,0,1,272.87,266.21Z" transform="translate(0)" />
          <path id="sun_15_3" d="M190.07,301.75a261,261,0,0,1,52.16-69.19L217,204.92A305.07,305.07,0,0,0,156.42,285Z" transform="translate(0)" />
          <path id="sun_15_4" d="M217.27,205.21l-25.4-27.8a341.55,341.55,0,0,0-68.6,91.1l33.5,16.7A302.47,302.47,0,0,1,217.27,205.21Z" transform="translate(0)" />
          <path id="sun_15_5" d="M116.07,264.9a345.91,345.91,0,0,1,70.49-93.42l-25.23-27.63a387.46,387.46,0,0,0-78.9,104.33Z" transform="translate(0)" />
          <path id="sun_15_6" d="M161.67,144.21l-25.3-27.8a425.12,425.12,0,0,0-87.1,115.3l33.6,16.7A387,387,0,0,1,161.67,144.21Z" transform="translate(0)" />
        </g>
        <g id="sun_14">
          <path id="sun_14_1" d="M243.52,402.65a179.28,179.28,0,0,1,15.34-54.23l-33.69-16.74a216.05,216.05,0,0,0-19.1,67.57Z" transform="translate(0)" />
          <path id="sun_14_2" d="M225.67,331.91l-33.6-16.8a255.23,255.23,0,0,0-22.8,80.7l37.3,3.4A220.88,220.88,0,0,1,225.67,331.91Z" transform="translate(0)" />
          <path id="sun_14_3" d="M161.53,395.07a263.24,263.24,0,0,1,23.63-83.41l-33.64-16.72a299,299,0,0,0-27.45,96.73Z" transform="translate(0)" />
          <path id="sun_14_4" d="M151.87,295.11l-33.6-16.7a336.92,336.92,0,0,0-31.2,109.8l37.3,3.4A301.46,301.46,0,0,1,151.87,295.11Z" transform="translate(0)" />
          <path id="sun_14_5" d="M79.22,387.46a345.93,345.93,0,0,1,31.94-112.64L77.52,258.09A380.69,380.69,0,0,0,41.77,384Z" transform="translate(0)" />
          <path id="sun_14_6" d="M78,258.31l-33.6-16.7A418.64,418.64,0,0,0,5,380.61l37.3,3.4A380.41,380.41,0,0,1,78,258.31Z" transform="translate(0)" />
        </g>
        <g id="sun_13">
          <path id="sun_13_1" d="M242.41,413.58l-37.34-3.41c-.3,4.92-.5,9.93-.5,14.84a222.26,222.26,0,0,0,6.92,55l36.19-10.33A182.78,182.78,0,0,1,242.41,413.58Z" transform="translate(0)" />
          <path id="sun_13_2" d="M205.07,425c0-5,.2-9.9.5-14.8l-37.4-3.5a257.58,257.58,0,0,0,7.7,83.4l36.1-10.2A221.45,221.45,0,0,1,205.07,425Z" transform="translate(0)" />
          <path id="sun_13_3" d="M160.33,406,123,402.6c-.5,7.41-.8,14.92-.8,22.43a304.68,304.68,0,0,0,10,77.6l36.25-10.31A266.55,266.55,0,0,1,160.33,406Z" transform="translate(0)" />
          <path id="sun_13_4" d="M122.57,425c0-7.5.3-15,.8-22.4L86,399.11a332.3,332.3,0,0,0,10.7,113.6l35.9-10.2A304.36,304.36,0,0,1,122.57,425Z" transform="translate(0)" />
          <path id="sun_13_5" d="M78.07,398.38,40.77,395q-1.2,14.87-1.2,30A389.39,389.39,0,0,0,52.69,525.24l36.24-10.31A346.74,346.74,0,0,1,78.07,398.38Z" transform="translate(0)" />
          <path id="sun_13_6" d="M40.07,425q0-15.15,1.2-30l-37.5-3.5a422.91,422.91,0,0,0,13.4,143.8l36-10.2A388.88,388.88,0,0,1,40.07,425Z" transform="translate(0)" />
        </g>
        <g id="sun_12">
          <path id="sun_12_1" d="M275.8,530.87a182.48,182.48,0,0,1-25.17-50.42l-36.19,10.22a218.18,218.18,0,0,0,31.38,62.76Z" transform="translate(0)" />
          <path id="sun_12_2" d="M215,490.51l-36.1,10.3a259.47,259.47,0,0,0,37.4,75l30-22.6A219.16,219.16,0,0,1,215,490.51Z" transform="translate(0)" />
          <path id="sun_12_3" d="M210,580.51a264.54,264.54,0,0,1-38.65-77.6l-36.15,10.21A301.63,301.63,0,0,0,180.07,603Z" transform="translate(0)" />
          <path id="sun_12_4" d="M135.57,513l-36.1,10.3a340.83,340.83,0,0,0,50.9,102.2l30-22.6A301.82,301.82,0,0,1,135.57,513Z" transform="translate(0)" />
          <path id="sun_12_5" d="M91.94,525.54,55.69,535.85A386.78,386.78,0,0,0,114.07,653l30-22.63A351.91,351.91,0,0,1,91.94,525.54Z" transform="translate(0)" />
          <path id="sun_12_6" d="M56.17,535.71l-36,10.2a421.68,421.68,0,0,0,64.5,129.3l29.9-22.6A383.87,383.87,0,0,1,56.17,535.71Z" transform="translate(0)" />
        </g>
        <g id="sun_11">
          <path id="sun_11_1" d="M282.41,539.7l-30,22.55a223.78,223.78,0,0,0,51.83,47.32l19.83-31.88A183.2,183.2,0,0,1,282.41,539.7Z" transform="translate(0)" />
          <path id="sun_11_2" d="M252.87,561.91l-30,22.7a258.34,258.34,0,0,0,62,56.4l19.8-31.9A219.78,219.78,0,0,1,252.87,561.91Z" transform="translate(0)" />
          <path id="sun_11_3" d="M216.7,589.32,186.76,612a304.56,304.56,0,0,0,74.31,67.69l19.82-31.94A264,264,0,0,1,216.7,589.32Z" transform="translate(0)" />
          <path id="sun_11_4" d="M187.07,611.71l-30,22.6a336.08,336.08,0,0,0,84.4,76.9l19.7-31.9A304.22,304.22,0,0,1,187.07,611.71Z" transform="translate(0)" />
          <path id="sun_11_5" d="M150.71,639.09l-29.93,22.63a389.72,389.72,0,0,0,96.62,88.21L237.23,718A351.35,351.35,0,0,1,150.71,639.09Z" transform="translate(0)" />
          <path id="sun_11_6" d="M121.17,661.41l-30,22.6A421.21,421.21,0,0,0,198,781.31l19.7-31.9A387.27,387.27,0,0,1,121.17,661.41Z" transform="translate(0)" />
        </g>
        <g id="sun_10">
          <path id="sun_10_1" d="M333.44,583.41l-19.75,32a219.05,219.05,0,0,0,65.47,25.37l6.91-37A183.73,183.73,0,0,1,333.44,583.41Z" transform="translate(0)" />
          <path id="sun_10_2" d="M314,614.91l-19.8,31.9a255,255,0,0,0,78.2,30.2l6.9-36.8A216.39,216.39,0,0,1,314,614.91Z" transform="translate(0)" />
          <path id="sun_10_3" d="M290.07,653.51l-19.7,31.94a300.74,300.74,0,0,0,93.7,36.34l6.91-37A263.8,263.8,0,0,1,290.07,653.51Z" transform="translate(0)" />
          <path id="sun_10_4" d="M270.57,685.11,250.77,717a334.61,334.61,0,0,0,106.5,41.1l6.9-36.7A300.35,300.35,0,0,1,270.57,685.11Z" transform="translate(0)" />
          <path id="sun_10_5" d="M246.54,723.8l-19.73,31.84a384.48,384.48,0,0,0,122,47.26l6.91-37A345.13,345.13,0,0,1,246.54,723.8Z" transform="translate(0)" />
          <path id="sun_10_6" d="M227.07,755.21l-19.8,32a422.07,422.07,0,0,0,134.8,52.1l6.8-36.8A381.71,381.71,0,0,1,227.07,755.21Z" transform="translate(0)" />
        </g>
        <g id="sun_9">
          <path id="sun_9_1" d="M460.16,642.75l-6.92-37a182.89,182.89,0,0,1-56.34,0l-6.92,37a220.31,220.31,0,0,0,70.18,0Z" transform="translate(0)" />
          <path id="sun_9_2" d="M425.07,645a224.25,224.25,0,0,1-35-2.8l-6.9,37a253.44,253.44,0,0,0,83.8-.1l-6.9-36.9A224.25,224.25,0,0,1,425.07,645Z" transform="translate(0)" />
          <path id="sun_9_3" d="M475.34,723.7l-6.91-37a265.1,265.1,0,0,1-86.72.1l-6.91,36.85a307.89,307.89,0,0,0,50.27,4.2A298.19,298.19,0,0,0,475.34,723.7Z" transform="translate(0)" />
          <path id="sun_9_4" d="M425.07,727.51a296.29,296.29,0,0,1-50.2-4.2l-6.9,37a339.41,339.41,0,0,0,114.2-.2l-6.9-36.8A296.29,296.29,0,0,1,425.07,727.51Z" transform="translate(0)" />
          <path id="sun_9_5" d="M490.55,805l-6.9-37a348.25,348.25,0,0,1-117.06.11L359.69,805a391.06,391.06,0,0,0,130.86,0Z" transform="translate(0)" />
          <path id="sun_9_6" d="M425.07,810a390.4,390.4,0,0,1-65.3-5.5l-6.9,36.9a422.39,422.39,0,0,0,144.5-.1l-6.9-36.8A391.59,391.59,0,0,1,425.07,810Z" transform="translate(0)" />
        </g>
        <g id="sun_8">
          <path id="sun_8_1" d="M516.6,583.3a180.6,180.6,0,0,1-52.53,20.46l6.91,37a217.89,217.89,0,0,0,65.47-25.37Z" transform="translate(0)" />
          <path id="sun_8_2" d="M470.87,640.21l6.9,36.9a253.44,253.44,0,0,0,78.1-30.4l-19.7-31.8A217.38,217.38,0,0,1,470.87,640.21Z" transform="translate(0)" />
          <path id="sun_8_3" d="M560.07,653.41a264.39,264.39,0,0,1-80.81,31.34l6.91,36.94a300.61,300.61,0,0,0,93.72-36.34Z" transform="translate(0)" />
          <path id="sun_8_4" d="M486.07,721.31l6.9,36.9a337,337,0,0,0,106.4-41.3l-19.7-31.8A300.32,300.32,0,0,1,486.07,721.31Z" transform="translate(0)" />
          <path id="sun_8_5" d="M603.6,723.8a345.73,345.73,0,0,1-109.14,42.35L501.37,803a384.48,384.48,0,0,0,122-47.26Z" transform="translate(0)" />
          <path id="sun_8_6" d="M501.27,802.51l6.9,36.9a427.25,427.25,0,0,0,134.7-52.2l-19.8-32A381.71,381.71,0,0,1,501.27,802.51Z" transform="translate(0)" />
        </g>
        <g id="sun_7">
          <path id="sun_7_1" d="M567.63,539.6a183,183,0,0,1-41.61,38l19.85,32a219.61,219.61,0,0,0,51.83-47.32Z" transform="translate(0)" />
          <path id="sun_7_2" d="M545.57,609.11l19.7,31.9a256.17,256.17,0,0,0,61.9-56.5l-29.9-22.6A221.19,221.19,0,0,1,545.57,609.11Z" transform="translate(0)" />
          <path id="sun_7_3" d="M663.48,611.85l-30-22.63a261.49,261.49,0,0,1-64,58.48l19.72,31.94A305,305,0,0,0,663.48,611.85Z" transform="translate(0)" />
          <path id="sun_7_4" d="M589,679.31l19.8,31.9a341.36,341.36,0,0,0,84.3-77l-29.9-22.6A304.69,304.69,0,0,1,589,679.31Z" transform="translate(0)" />
          <path id="sun_7_5" d="M729.36,661.72l-30-22.63A347,347,0,0,1,612.91,718L632.64,750A386.76,386.76,0,0,0,729.36,661.72Z" transform="translate(0)" />
          <path id="sun_7_6" d="M632.37,749.51l19.8,31.9A421.19,421.19,0,0,0,758.87,684L729,661.41A386.26,386.26,0,0,1,632.37,749.51Z" transform="translate(0)" />
        </g>
        <g id="sun_6">
          <path id="sun_6_1" d="M599.4,480.35a180,180,0,0,1-25.06,50.52l30,22.66a220.15,220.15,0,0,0,31.38-62.86Z" transform="translate(0)" />
          <path id="sun_6_2" d="M635.17,490.51a219.16,219.16,0,0,1-31.3,62.7l30,22.6a254.91,254.91,0,0,0,37.3-75.1Z" transform="translate(0)" />
          <path id="sun_6_3" d="M678.7,502.91a264.26,264.26,0,0,1-38.55,77.6l29.92,22.63a301.85,301.85,0,0,0,44.86-89.92Z" transform="translate(0)" />
          <path id="sun_6_4" d="M714.57,513.11a301.29,301.29,0,0,1-44.8,89.8l30,22.6a337,337,0,0,0,50.7-102.2Z" transform="translate(0)" />
          <path id="sun_6_5" d="M758.07,525.54a343.68,343.68,0,0,1-52,104.84L736,653a382.36,382.36,0,0,0,58.38-117.16Z" transform="translate(0)" />
          <path id="sun_6_6" d="M793.87,535.71a386.41,386.41,0,0,1-58.3,117l30,22.6a421.46,421.46,0,0,0,64.3-129.4Z" transform="translate(0)" />
        </g>
        <g id="sun_5">
          <path id="sun_5_1" d="M602.51,469.72,638.7,480a220.86,220.86,0,0,0,6.92-55c0-5-.2-9.92-.5-14.94l-37.49,3.51A179.61,179.61,0,0,1,602.51,469.72Z" transform="translate(0)" />
          <path id="sun_5_2" d="M681.87,406.71l-37.3,3.4c.3,4.9.5,9.9.5,14.9a221,221,0,0,1-6.9,54.9l36.1,10.3A255.12,255.12,0,0,0,681.87,406.71Z" transform="translate(0)" />
          <path id="sun_5_3" d="M681.8,492.4,718,502.61A304.68,304.68,0,0,0,728,425c0-7.51-.3-15-.8-22.43l-37.45,3.51A260.75,260.75,0,0,1,681.8,492.4Z" transform="translate(0)" />
          <path id="sun_5_4" d="M764.07,399.21l-37.3,3.4c.5,7.4.8,14.8.8,22.4a304.36,304.36,0,0,1-10,77.5l36.1,10.3A342.53,342.53,0,0,0,764.07,399.21Z" transform="translate(0)" />
          <path id="sun_5_5" d="M809.37,395l-37.45,3.51A346.85,346.85,0,0,1,761.21,515l36.14,10.31A384.82,384.82,0,0,0,810.57,425Q810.57,409.85,809.37,395Z" transform="translate(0)" />
          <path id="sun_5_6" d="M846.17,391.61l-37.3,3.4q1.2,14.85,1.2,30a385.73,385.73,0,0,1-13.2,100.2l36.1,10.2A420.09,420.09,0,0,0,846.17,391.61Z" transform="translate(0)" />
        </g>
        <g id="sun_4">
          <path id="sun_4_1" d="M644.12,399.25A220.79,220.79,0,0,0,625,331.78l-33.69,16.74a182.46,182.46,0,0,1,15.44,54.23Z" transform="translate(0)" />
          <path id="sun_4_2" d="M643.57,399.31l37.4-3.5a257.53,257.53,0,0,0-22.9-80.6l-33.6,16.7A220.92,220.92,0,0,1,643.57,399.31Z" transform="translate(0)" />
          <path id="sun_4_3" d="M688.81,395l37.35-3.4A301.67,301.67,0,0,0,698.72,295l-33.65,16.68A264.64,264.64,0,0,1,688.81,395Z" transform="translate(0)" />
          <path id="sun_4_4" d="M725.77,391.61l37.4-3.5a338.4,338.4,0,0,0-31.4-109.7l-33.5,16.7A301.46,301.46,0,0,1,725.77,391.61Z" transform="translate(0)" />
          <path id="sun_4_5" d="M771.12,387.36,808.37,384a384.05,384.05,0,0,0-35.75-125.87L739,274.87A345.51,345.51,0,0,1,771.12,387.36Z" transform="translate(0)" />
          <path id="sun_4_6" d="M807.87,384l37.5-3.5a426.29,426.29,0,0,0-39.6-138.9l-33.6,16.7A380.41,380.41,0,0,1,807.87,384Z" transform="translate(0)" />
        </g>
        <g id="sun_3">
          <path id="sun_3_1" d="M586.37,338.59l33.7-16.74a223.17,223.17,0,0,0-42.31-56L552.4,293.62A184.38,184.38,0,0,1,586.37,338.59Z" transform="translate(0)" />
          <path id="sun_3_2" d="M619.57,322.11l33.6-16.8a257.71,257.71,0,0,0-50.6-66.8l-25.3,27.7A221,221,0,0,1,619.57,322.11Z" transform="translate(0)" />
          <path id="sun_3_3" d="M660.17,301.85l33.55-16.72A300.83,300.83,0,0,0,633.14,205l-25.23,27.74A264.92,264.92,0,0,1,660.17,301.85Z" transform="translate(0)" />
          <path id="sun_3_4" d="M693.37,285.31l33.6-16.8a334.25,334.25,0,0,0-68.8-90.9L633,205.31A302.22,302.22,0,0,1,693.37,285.31Z" transform="translate(0)" />
          <path id="sun_3_5" d="M734.07,264.9l33.64-16.72a385.77,385.77,0,0,0-78.9-104.33l-25.33,27.73A345.84,345.84,0,0,1,734.07,264.9Z" transform="translate(0)" />
          <path id="sun_3_6" d="M767.27,248.41l33.6-16.7a421.48,421.48,0,0,0-87.2-115.2l-25.2,27.7A385.73,385.73,0,0,1,767.27,248.41Z" transform="translate(0)" />
        </g>
        <g id="sun_2">
          <path id="sun_2_1" d="M544.17,286.16l25.36-27.77a220.53,220.53,0,0,0-59.65-37l-13.63,35.09A180.71,180.71,0,0,1,544.17,286.16Z" transform="translate(0)" />
          <path id="sun_2_2" d="M569.17,258.81l25.3-27.8a257.47,257.47,0,0,0-71.3-44.1l-13.5,35A219.65,219.65,0,0,1,569.17,258.81Z" transform="translate(0)" />
          <path id="sun_2_3" d="M599.8,225.25l25.27-27.74a303.72,303.72,0,0,0-85.31-53l-13.69,35.18A263.1,263.1,0,0,1,599.8,225.25Z" transform="translate(0)" />
          <path id="sun_2_4" d="M624.77,197.81l25.3-27.8a337.43,337.43,0,0,0-97-59.9l-13.5,34.8A305.39,305.39,0,0,1,624.77,197.81Z" transform="translate(0)" />
          <path id="sun_2_5" d="M569.46,67.45l-13.52,35A347.86,347.86,0,0,1,655.47,164L680.7,136.4A385.86,385.86,0,0,0,569.46,67.45Z" transform="translate(0)" />
          <path id="sun_2_6" d="M680.37,136.81l25.3-27.8a425.21,425.21,0,0,0-122.8-76.1l-13.6,35A385.79,385.79,0,0,1,680.37,136.81Z" transform="translate(0)" />
        </g>
        <g id="sun_1">
          <path id="sun_1_1" d="M486.07,252.48l13.64-35.09a218.55,218.55,0,0,0-69.08-12.83v37.59A183.32,183.32,0,0,1,486.07,252.48Z" transform="translate(0)" />
          <path id="sun_1_2" d="M499.47,217.91l13.5-35a254.43,254.43,0,0,0-82.4-15.3v37.5A218.6,218.6,0,0,1,499.47,217.91Z" transform="translate(0)" />
          <path id="sun_1_3" d="M430.58,122.22v37.55a263.42,263.42,0,0,1,85.21,15.82l13.52-35.05A301.69,301.69,0,0,0,430.58,122.22Z" transform="translate(0)" />
          <path id="sun_1_4" d="M529.17,140.91l13.6-35A341.41,341.41,0,0,0,430.57,85v37.6A300.91,300.91,0,0,1,529.17,140.91Z" transform="translate(0)" />
          <path id="sun_1_5" d="M545.63,98.59l13.61-35a386,386,0,0,0-128.66-24V77.16A344.85,344.85,0,0,1,545.63,98.59Z" transform="translate(0)" />
          <path id="sun_1_6" d="M559.07,64l13.5-35a420.23,420.23,0,0,0-142-26.4V40A385.78,385.78,0,0,1,559.07,64Z" transform="translate(0)" />
        </g>
      </g>
    </svg>
  );
};

export default Sun;
