import React, {
  useState, useEffect, useContext, useRef,
} from 'react';
import {
  Button, Navbar, Nav, Container, Row, Col, Dropdown, Form, InputGroup,
} from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { useTranslation } from 'react-i18next';

import { Typeahead } from 'react-bootstrap-typeahead';

import SignInModal from './SignInModal';
import ProjectModal from './ProjectModal';
import UserService from '../services/UserService';
import ProjectService from '../services/ProjectService';

import { ProjectContext } from '../contexts/ProjectContext';
import { SunContext } from '../contexts/SunContext';
import { UserContext } from '../contexts/UserContext';

const NavBar = () => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const typeahead = useRef(null);
  const [user, setUser] = useContext(UserContext);
  const [sun, setSun] = useContext(SunContext);
  const [projects, setProjects] = useState([]);
  const [project, setProject] = useContext(ProjectContext);

  useEffect(() => {
    async function fetchProjects() {
      if (localStorage.getItem('user')) {
        const projs = await ProjectService.getUserProjects();
        setProjects(projs);
      } else {
        setProjects([]);
      }
    }
    fetchProjects();
  }, [project, user, sun]);

  const handleOnChange = (selected) => {
    if (selected[0]) {
      setProject({ name: selected[0].name, id: selected[0].id });
      setSun(null);
      history.push({
        pathname: '/project',
        state: { name: selected[0].name, id: selected[0].id },
      });
      typeahead.current.clear();
    } else {
      setProject(null);
      history.push({
        pathname: '/',
      });
    }
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const [signInOpen, setModalSignInOpen] = useState(false);
  function onSignInClick() {
    setModalSignInOpen(true);
  }
  function onSignUpClick() {
    setModalSignInOpen(true);
  }

  const onLogoutClick = () => {
    UserService.logout();
    window.location.assign('/');
  };

  const [modalProjectOpen, setModalProjectOpen] = useState(false);
  function onProjectClick() {
    setModalProjectOpen(true);
  }

  return (
    <>
      <Navbar expand="lg" fixed="top" className="navbar-north" role="navigation">
        <Container id="navbarNorthContainer">
          <Row noGutters>

            <Col lg="3">
              <header>
                <Link to="/" className="logotype logotype-white" role="banner">Sweco version 0.1</Link>
              </header>
            </Col>

            <Navbar.Toggle aria-controls="navbarNorthNav" aria-label={t('toggleNav')}>
              <span className="btn-nav-box">
                <span className="btn-nav-inner" />
              </span>
            </Navbar.Toggle>

            <div className="nav-toolbar">

              <div>
                <Dropdown>
                  <Dropdown.Toggle variant="link" className="btn-lang" id="language-menu-toggle">
                    <span className="sr-only">{t('selectLanguage')}</span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu alignRight className="dropdown-menu-wrap" role="menu" id="language-menu">
                    <div>
                      <Dropdown.Header>
                        <span>{t('language')}</span>
                      </Dropdown.Header>
                      <Dropdown.Divider />
                      {/* <button type="button" className="dropdown-item"
                      onClick={() => changeLanguage("dk")}>
                        <span>Dansk</span>
                      </button> */}
                      <Dropdown.Item active onClick={() => changeLanguage('en')}>
                        English
                      </Dropdown.Item>
                      {/* <button type="button" className="dropdown-item active"
                      onClick={() => changeLanguage("sv")}>
                        <span>Svenska</span>
                      </button> */}
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              <div>
                <Dropdown>
                  <Dropdown.Toggle variant="link" className="btn-user" id="user-menu-toggle">
                    <span className="sr-only">{t('userSettings')}</span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu alignRight className="dropdown-menu-wrap" role="menu" id="user-menu">
                    <div>
                      {localStorage.getItem('user')
                        ? (
                          <>
                            <Dropdown.Header>
                              <span>{UserService.getCurrentUser().name}</span>
                            </Dropdown.Header>
                            <Dropdown.Divider />
                            <button type="button" className="dropdown-item" onClick={onLogoutClick}>
                              <i className="far fa-sign-in" />
                              <span>{t('logout')}</span>
                            </button>
                            {UserService.getCurrentUser().isAdmin
                              ? (
                                <LinkContainer to="/admin">
                                  <button type="button" className="dropdown-item">
                                    <i className="far fa-cog" />
                                    <span>{t('adminSettings')}</span>
                                  </button>
                                </LinkContainer>
                              )
                              : <></>}
                          </>
                        )
                        : (
                          <>
                            <button type="button" className="dropdown-item" onClick={onSignUpClick}>
                              <i className="far fa-user-plus mr-2" />
                              {t('register')}
                            </button>
                            <button type="button" className="dropdown-item" onClick={onSignInClick}>
                              <i className="far fa-sign-in" />
                              <span>{t('signIn')}</span>
                            </button>
                          </>
                        )}
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>

            </div>
            {localStorage.getItem('user')
              ? (
                <Col lg="9">
                  <Navbar.Collapse id="navbarNorthNav">

                    <div className="navbar-nav mr-md-14">
                      <Nav className="nav">
                        <li className="nav-item">
                          <Button className="nav-link" onClick={onProjectClick}>{t('projectAdd')}</Button>
                        </li>
                        <li className="nav-item">
                          <LinkContainer to="/about">
                            <Button className="nav-link">{t('navAbout')}</Button>
                          </LinkContainer>
                        </li>
                      </Nav>
                    </div>

                    <div className="navbar-search">
                      <Form>
                        <InputGroup>
                          <div>
                            <Typeahead
                              id="searchSelectProject"
                              labelKey={(option) => `${option.name}`}
                              options={projects}
                              placeholder={t('projectSelect')}
                              clearButton
                              onChange={handleOnChange}
                              ref={typeahead}
                            />
                          </div>
                        </InputGroup>
                      </Form>
                    </div>

                  </Navbar.Collapse>
                </Col>
              )
              : <></>}
          </Row>
        </Container>
      </Navbar>

      {signInOpen
        && <SignInModal open={signInOpen} onHide={() => setModalSignInOpen(false)} />}

      {modalProjectOpen
        && (
          <ProjectModal
            open={modalProjectOpen}
            projects={projects}
            newProject={setProjects}
            onHide={() => setModalProjectOpen(false)}
          />
        )}
    </>
  );
};

export default NavBar;
